import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper } from '@fortawesome/pro-light-svg-icons';
import { useFetchAuthToken } from '../home/redux/hooks';
import { DesktopHeader } from '../common';

export default function UserNotApproved() {
  const { userApproved } = useFetchAuthToken();

  if (userApproved) {
    return <Redirect to="/dashboard" />
  }

  return (
    <>
    <DesktopHeader />
    <div className="container">
      <div className="common-user-not-approved">
        <FontAwesomeIcon icon={faHandPaper} className="theme-text-primary m-auto d-block" size='4x' />
        <h1 className="text-center mt-3">Not approved</h1>
        <h2 className="text-center font-weight-normal px-2 mt-3 ">
          Your account is not active. Contact your admin to gain access.
          While you wait, you can edit<Link to={'/user/profile'}>your profile.</Link>
        </h2>
      </div>
    </div>
    </>
  );
};

UserNotApproved.propTypes = {};
UserNotApproved.defaultProps = {};
