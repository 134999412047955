import React, { useEffect, useMemo, useCallback, useState, useRef, Fragment } from 'react';
// import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import capitalize from 'lodash/capitalize';
import intersection from 'lodash/intersection';
import { Link, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faSort, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisH, faClock, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useFetchAssignedUsers } from './redux/hooks';
import Roadmap from './components/Roadmap';
import { Loader, ActionMenu, UserAvatar, DesktopHeader } from '../common';
import { EditUserRoadmapModal } from '../manage';
import { useFetchUpdates } from '../common/redux/hooks';
import { useFetchUser } from '../user/redux/hooks';
import raiseHandIcon from '../../images/icons/raise-hand.svg';
import CoachAddUser from './CoachAddUser';
import CoachUpcommingCalls from './CoachUpcomingCalls';
import { AddUserModal } from './CoachAddUserPage';
import { AddCallModal } from './CoachAddCallsPage';


import InfiniteScroll from 'react-infinite-scroller';
import Spinner from 'react-bootstrap/Spinner';
import useWindowResize from '../common/useWindowResize'


dayjs.extend(relativeTime)

function SortMenuItem(props) {
  return <Form.Check
    type='radio'
    name='sort'
    {...props}
  />;
}

const SortMenu = ({ show, onHide, onSort, value, sortByOptions }) => {
  return (
    <Modal
      className={clsx(
        "dashboard-admin-page",
        "sort-menu",
        "modal-mobile-slide-from-bottom"
      )}
      show={show}
      onHide={onHide}
    >
      <Modal.Body className="text-center p-0">
        <div className="position-relative mrm-px-1 mrm-pt-1 border-bottom">
          <div className='position-absolute font-weight-bold cancel' onClick={onHide}>Cancel</div>
          <p className="font-weight-bold text-normal">Sort by</p>
        </div>
        <Form>
          {sortByOptions.map(({ label, field }, key) => (
            <SortMenuItem
              key={key}
              defaultChecked={field === value}
              onClick={onSort(field)}
              id={`sort-${key}`}
              label={label}
              className="mrm-py-1"
            />
          ))}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

// const EllipsisMenuToggle = React.forwardRef(({ children, onClick }, ref) => {
//   return <Button
//     className="btn-detail btn-secondary"
//     ref={ref}
//     onClick={(e) => {
//       e.preventDefault();
//       onClick(e);
//     }}
//   >
//     <FontAwesomeIcon icon={faEllipsisH} />
//   </Button>;
// });

const SortMenuToggle = React.forwardRef(({ children, onClick, sortByLabel }, ref) => {
  return <Button
    className="sort sort-desktop"
    variant="white"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    Sort by:
    {sortByLabel && <span className="sort-by-label">{sortByLabel}</span>}
    <FontAwesomeIcon icon={faCaretDown} />
  </Button>;
});

const UserCard = (
  {
    user,
    onDetail,
    toggleShowRoadmaps = true,
    canEditAssign,
    handleEditUserRoadmapClick,
  }
) => {
  const [showRoadmaps, setShowRoadmaps] = useState(!toggleShowRoadmaps)

  const { updates } = useFetchUpdates()
  const location = useLocation()

  const handleClick = useCallback(e => setShowRoadmaps(s => !s), [])

  const isRoadmapUnseen = useCallback(roadmapId => {
    if (!!updates?.unseen_activity?.[user.id]) {
      return updates.unseen_activity[user.id].roadmaps.includes(roadmapId)
    }
    return false
  }, [updates, user])

  const hasUnseenRoadmap = useMemo(() => {
    if (!!updates?.unseen_activity?.[user.id]) {
      return intersection(updates.unseen_activity[user.id].roadmaps, user.roadmaps_info.map(i => i.id)).length > 0
    }
    return false
  }, [updates, user])

  return (
    <Card
      onClick={toggleShowRoadmaps ? handleClick : undefined}
      className={clsx('mrm-mb-1 student-card', { 'toggle-show-roadmaps': toggleShowRoadmaps })}
    >
      <Card.Body className="position-relative mrm-py-0_75 mrm-px-1">
        <Link
          className="more-btn-desktop"
          onClick={typeof onDetail === "function" ? onDetail : undefined}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </Link>
        {/* <Dropdown className="d-none d-lg-block">
          <Dropdown.Toggle as={EllipsisMenuToggle} />
          <Dropdown.Menu align="right">
            <Dropdown.Item as={Link} to={`/manage/user/${user.id}`}>
              View Profile
            </Dropdown.Item>
            {canEditAssign && <Dropdown.Item onClick={handleEditUserRoadmapClick}>
              Edit Assigned Roadmaps
            </Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown> */}
        {hasUnseenRoadmap && (
          <span className="student-dot dot float-left" />
        )}
        <div className="d-flex">
          <UserAvatar user={user} className="mrm-mr-0_5" />
          <div className="my-auto">
            <p className="mb-0 name">
              <strong>{capitalize(user.first_name)} {capitalize(user.last_name)}</strong>
            </p>
            {user.red_assessments_count > 0 && (
              <span className="item-count red-count">
                <img src={raiseHandIcon} alt="" />
                {user.red_assessments_count}
              </span>
            )}
            {user.approval_pending_items_count > 0 && (
              <span className="item-count gray-count">
                <FontAwesomeIcon icon={faClock} size='xs' />
                {user.approval_pending_items_count}
              </span>
            )}
          </div>
        </div>
        {showRoadmaps && (
          user.roadmaps_info.length > 0
            ? user.roadmaps_info.map(roadmap => (
              <Link
                key={roadmap.id}
                to={{
                  pathname: `/roadmap/${roadmap.id}`,
                  search: `?user=${user.id}`,
                  state: { backLink: location },
                }}
                className="student-card-roadmap-link"
              >
                <Roadmap
                  unseen={isRoadmapUnseen(roadmap.id)}
                  data={roadmap}
                  className="mt-3"
                />
              </Link>
            ))
            :
            <>
              <div className="no-data theme-text-light mrm-mb-0_5">No Roadmaps</div>
              <Link to={{
                pathname: `/manage/user/${user.id}/edit-roadmap`,
                state: { backLink: location },
              }}>
                <Button className="d-block mx-auto" variant="primary">
                  Assign A Roadmap
                </Button>
              </Link>
            </>
        )}
        <p className="last-login text-lighter mt-2">
          Last login: <strong>
            {user.last_login ? dayjs(user.last_seen).fromNow() : "Never"}
          </strong>
        </p>
      </Card.Body>
    </Card>
  )
}

function AdminPageHeader(
  {
    type,
    replaceStringWithSynonyms,
    assignedUsers,
    assignedUsersFull,
    handleSearchChange,
    handleSortClick,
    sortByOptions = [],
    sortByValue,
    onSort,
    canAddUsers,
    handleNewUserSuccess
  }
) {
  const sortByLabel = sortByOptions.filter(o => o.field === sortByValue)[0];
  const { user } = useFetchUser();
  const windowSize = useWindowResize()


  return (<>
    <div className="text-right mrm-mb-0_75 d-none">
      {/* TODO: Restore button displaying when it'll make sense */}
      <Button variant="white">
        <FontAwesomeIcon icon={faChartArea} size='xs' />
        Roadmap Stats
      </Button>
    </div>
    <div className={clsx('d-flex justify-content-between', { 'mrm-mb-1': windowSize.width > 991 })}>
      <h2 className="dashboard-title theme-text-normal mrm-mb-1">
        {type === 'Admin' ? 'All' : 'My'} {replaceStringWithSynonyms('Students')}&nbsp;
        {assignedUsers && <strong>({assignedUsersFull ? assignedUsersFull.count : 0})</strong>}
      </h2>
      {canAddUsers && <CoachAddUser isDesktop={true} handleNewUserSuccess={handleNewUserSuccess} />}
    </div>

    {window.location.pathname === "/dashboard/coach" &&
      <div className="d-flex justify-content-between">
        {user && user.students && user.students.length > 0 ?
          <>
            <div className="common-search-bar mrm-mb-1">
              <Form.Control
                className='search'
                placeholder='Search for someone...'
                onChange={handleSearchChange}
              />
              <FontAwesomeIcon icon={faSearch} size="xs" className="search-icon" />
            </div>
            <Button
              className="sort d-lg-none mrm-mb-1"
              variant="white"
              onClick={typeof handleSortClick === "function" ? handleSortClick : undefined}
            >
              <FontAwesomeIcon icon={faSort} size='xs' />
              Sort
            </Button>
            <Dropdown className="d-none d-lg-block mrm-mb-1">
              <Dropdown.Toggle as={SortMenuToggle} sortByLabel={sortByLabel && sortByLabel.label} />
              <Dropdown.Menu align="right">
                <Form>
                  {sortByOptions.map(({ label, field }, key) => (<Fragment key={key}>
                    {key !== 0 && <Dropdown.Divider />}
                    <Dropdown.Item
                      as={SortMenuItem}
                      onClick={onSort(field)}
                      defaultChecked={field === sortByValue}
                      label={label}
                      id={`sort-${key}`}
                    />
                  </Fragment>))}
                </Form>
              </Dropdown.Menu>
            </Dropdown>
          </> :
          <p className="no-data d-none d-lg-block mb-0">No {replaceStringWithSynonyms('students')} yet.</p>
        }
      </div>
    }

    {window.location.pathname === "/dashboard/admin" &&
      <div className="d-flex justify-content-between mrm-mb-1">
        <div className="common-search-bar">
          <Form.Control
            className='search'
            placeholder='Search for someone...'
            onChange={handleSearchChange}
          />
          <FontAwesomeIcon icon={faSearch} size="xs" className="search-icon" />
        </div>
        <Button
          className="sort d-lg-none"
          variant="white"
          onClick={typeof handleSortClick === "function" ? handleSortClick : undefined}
        >
          <FontAwesomeIcon icon={faSort} size='xs' />
          Sort
        </Button>
        <Dropdown className="d-none d-lg-block">
          <Dropdown.Toggle as={SortMenuToggle} sortByLabel={sortByLabel && sortByLabel.label} />
          <Dropdown.Menu align="right">
            <Form>
              {sortByOptions.map(({ label, field }, key) => (<Fragment key={key}>
                {key !== 0 && <Dropdown.Divider />}
                <Dropdown.Item
                  as={SortMenuItem}
                  onClick={onSort(field)}
                  defaultChecked={field === sortByValue}
                  label={label}
                  id={`sort-${key}`}
                />
              </Fragment>))}
            </Form>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    }
  </>);
}

export default function AdminPage() {
  const { fetchAssignedUsers, fetchAssignedUsersPending } = useFetchAssignedUsers();
  const { user, replaceStringWithSynonyms, fetchUser } = useFetchUser()

  const history = useHistory()
  const location = useLocation()

  const [detailMenu, setDetailMenu] = useState(null)
  const [sortMenu, setSortMenu] = useState(false)
  const [sortBy, setSortBy] = useState(null)
  const search = useRef(null)

  const type = history.location.pathname === '/dashboard/admin' ? 'Admin' : 'Coach';

  const [assignedUsers, setAssignedUsers] = useState(null);
  const [assignedUsersFull, setAssignedUsersFull] = useState(null);

  const handleNewUserSuccess = useCallback(async () => {
    await setAssignedUsers(await fetchAssignedUsers({
      role: type.toLowerCase(),
      page: 0,
      search: search.current,
      ordering: sortBy,
      pageSize
    }));
    await setAssignedUsersFull(
      await fetchAssignedUsers({
        role: type.toLowerCase(),
        search: '',
        ordering: sortBy,
        pageSize,
      }),
    );
    await fetchUser()
  }, [fetchAssignedUsers, search, sortBy, type, fetchUser])

  const userGroups = user ? user.groups : []

  const canEditAssign = (user ? !!user.features.coach_can_assign_roadmaps : false) || userGroups.includes('Admin');

  const canCoachAddUsers = (user ? !!user.features.coaches_can_add_users : false) || userGroups.includes('Coach');
  const canCoachCalls = (user ? !!user.features.coaches_can_add_calls : false) && userGroups.includes('Coach');

  useEffect(() => {
    const getAssignedUsers = async () => {
      setAssignedUsers(await fetchAssignedUsers({
        role: type.toLowerCase(),
        search: search.current,
        ordering: sortBy,
        pageSize
      }));

      setAssignedUsersFull(
        await fetchAssignedUsers({
          role: type.toLowerCase(),
          search: '',
          ordering: sortBy,
          pageSize,
        }),
      );
    }

    getAssignedUsers();
  }, [fetchAssignedUsers, search, sortBy, type])


  const handleUserDetailClick = useCallback(userId => () => setDetailMenu(userId), [])

  const handleHideDetailMenu = useCallback(() => setDetailMenu(false), [])

  const debouncedFetchUsers = debounce(async q => {
    search.current = typeof q === "undefined" ? search.current : q
    setAssignedUsers(await fetchAssignedUsers({
      role: type.toLowerCase(),
      search: search.current,
      ordering: sortBy,
      pageSize
    }));
  }, 500)

  const handleSearchChange = useCallback(e => debouncedFetchUsers(e.target.value), [debouncedFetchUsers])

  const handleSortClick = useCallback(() => setSortMenu(true), [])

  const handleHideSortMenu = useCallback(() => setSortMenu(false), [])

  const handleSortMenuClick = useCallback(sortBy => async e => {
    setSortBy(sortBy)
    setSortMenu(false)

    setAssignedUsers(await fetchAssignedUsers({
      role: type.toLowerCase(),
      search: search.current,
      ordering: sortBy,
      pageSize
    }));
  }, [
    type,
    fetchAssignedUsers,
  ])

  const [showEditUserRoadmapModal, setShowEditUserRoadmapModal] = useState(false);

  const [editRoadmapUserId, setEditRoadmapUserId] = useState(null);

  const handleEditUserRoadmapClick = useCallback(userId => () => {
    setEditRoadmapUserId(userId);
    setShowEditUserRoadmapModal(true);
  }, []);

  const handleEditUserRoadmapModalHide = useCallback((saved) => {
    setShowEditUserRoadmapModal(false);
    setEditRoadmapUserId(null);
    if (typeof saved === "boolean" && saved) debouncedFetchUsers();
  }, [debouncedFetchUsers]);

  // Infinite scroller

  const pageSize = 14;

  const [infiniteHasMore, setInfiniteHasMore] = useState(false);
  const infiniteCurrentPageRef = useRef(1);
  const infiniteIsFetching = useRef(false);

  useEffect(() => {
    if (!!assignedUsers) {
      if (!!assignedUsers.next) {
        setInfiniteHasMore(true);
      }

      if (!!assignedUsers.count && !!assignedUsers.results && assignedUsers.count === assignedUsers.results.length) {
        setInfiniteHasMore(false);
      }
    }
  }, [assignedUsers]);

  const InfiniteLoader = useCallback(() => {
    return (
      <div className="infinite-scroll-loader">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }, []);

  const renderContent = useCallback(
    ({ toggleShowRoadmaps } = {}) => {

      const infiniteFetchMoreData = async () => {
        if (infiniteHasMore && !infiniteIsFetching.current) {
          infiniteIsFetching.current = true;

          const newFetchData = await fetchAssignedUsers({
            role: type.toLowerCase(),
            search: search.current,
            ordering: sortBy,
            pageSize,
            page: infiniteCurrentPageRef.current
          });

          if (!(!!newFetchData && !!newFetchData.next)) {
            setInfiniteHasMore(false);
          }

          setAssignedUsers(prevState => ({
            ...newFetchData,
            results: [...prevState.results, ...newFetchData.results],
          }));

          infiniteCurrentPageRef.current = infiniteCurrentPageRef.current + 1;
          infiniteIsFetching.current = false;
        }
      };

      if (!!!assignedUsers) {
        return <div><Loader /></div>;
      } else if (assignedUsers.results.length === 0) {
        return;
      } else {
        return (
          <>
            <InfiniteScroll
              pageStart={0}
              loadMore={infiniteFetchMoreData}
              hasMore={infiniteHasMore}
              loader={<InfiniteLoader />}
            >
              {assignedUsers.results.map(u => (
                <UserCard
                  key={u.id}
                  user={u}
                  onDetail={handleUserDetailClick(u.id)}
                  toggleShowRoadmaps={toggleShowRoadmaps}
                  canEditAssign={canEditAssign}
                  handleEditUserRoadmapClick={handleEditUserRoadmapClick(u.id)}
                />
              ))}
            </InfiniteScroll>

            {fetchAssignedUsersPending && !!assignedUsers.results.length === 0 && <Loader />}
          </>
        );
      }
    },
    [
      fetchAssignedUsersPending,
      assignedUsers,
      handleUserDetailClick,
      canEditAssign,
      handleEditUserRoadmapClick,
      infiniteHasMore,
      fetchAssignedUsers,
      sortBy,
      type
    ],
  );
  const actionMenuItems = useCallback(() => {
    const user = assignedUsers.results.find(u => u.id === detailMenu)

    const items = [
      { user },
      {
        to: {
          pathname: `/manage/user/${user.id}`,
          state: { backLink: location },
        },
        label: 'View Profile',
      },
    ]

    if (canEditAssign) {
      items.push({
        to: {
          pathname: `/manage/user/${user.id}/edit-roadmap`,
          state: { backLink: location },
        },
        label: 'Edit Assigned Roadmaps',
      })
    }

    if (canCoachAddUsers) {
      items.push({
        to: {
          pathname: `/manage/user/${user.id}/edit-profile`,
          state: { backLink: location },
        },
        label: 'Edit Account',
      })
    }

    return items
  }, [assignedUsers, detailMenu, canEditAssign, location, canCoachAddUsers])

  const sortByOptions = [
    { label: 'First Name', field: 'first_name' },
    { label: 'Last Name', field: 'last_name' },
    { label: 'Most Progress', field: '-max_progress' },
    { label: replaceStringWithSynonyms('Most Red Assessments'), field: '-red_assessments_count' },
    { label: 'Last Login', field: '-last_seen' }
  ]

  const [showCoachAddUserModal, setShowCoachAddUserModal] = useState(false);

  const handleCoachAddUserModalHide = () => {
    setShowCoachAddUserModal(false);
  };

  const [showAddCallModal, setShowAddCallModal] = useState(false);

  const handleAddCallModalHide = () => {
    setShowAddCallModal(false);
  };

  const now = new Date();
  const newAccount = user && dayjs(user.date_joined).add(14, 'day') > dayjs(now);
  const windowSize = useWindowResize()
  const isMobile = windowSize.width < 991;

  const [onboardingState, setOnboardingState] = useState({
    loaded: false,
    state: false,
    permission: false,
    hasRoadmap: false
  });
  
  const  handleOnboardingState =  useCallback( async () => {

    const imCoach = user && user.groups && user.groups.includes('Coach') && window.location.pathname === "/dashboard/coach";
    const newCoach = imCoach && user.features.coaches_can_add_users && assignedUsers && user.students.length === 0;
    const newCoachCantAdd = imCoach && !user.features.coaches_can_add_users && assignedUsers && user.students.length === 0;
    const hasUserNoRoadmap = imCoach && user.features.coaches_can_add_users && assignedUsersFull && assignedUsersFull.count > 0 && assignedUsersFull.count === assignedUsersFull.results.filter(u => u.roadmaps_info.length === 0).length;
    const hasUserandRoadmap = imCoach && user.features.coaches_can_add_users && assignedUsersFull && assignedUsersFull.count > assignedUsersFull.results.filter(u => u.roadmaps_info.length === 0).length && newAccount;

      if (newCoach) {
        setOnboardingState({ 
          state: true,
          permission: true,
          loaded: true,
      })
    }
      if (newCoachCantAdd) {
        setOnboardingState({ 
          state: true,
          permission: false,
          loaded: true,
        })
      }
      if (hasUserNoRoadmap) {
        setOnboardingState({ 
          state: true,
          hasRoadmap: false,
          loaded: true,
        })
      }
      if (hasUserandRoadmap) {
        setOnboardingState({ 
          state: true,
          hasRoadmap: true,
          loaded: true,
        })
      }
    },[user, assignedUsers, assignedUsersFull, newAccount]);

  useEffect(() => {
    handleOnboardingState()
  },[assignedUsers, assignedUsersFull, newAccount, handleOnboardingState]);


  function CoachMessagePrompt() {

    let coachMessagePrompt;

    if (onboardingState.state) {
      coachMessagePrompt =
        <>
          <h2 className="font-size-largest"><span role="img" aria-label="hand-wave">👋 </span> Welcome to {user && user.company_name}, {user && user.first_name}!</h2>
          <p>You can start by adding your first {replaceStringWithSynonyms('student')}. Once you add them, they will recieve an email inviting them to join you on the app.</p>
          {isMobile ?
            <Link className="w-fit-content" to="/dashboard/coach-add-user">
              <Button className="d-block mrm-px-1_5 mrm-py-0_75" variant="primary"><FontAwesomeIcon icon={faPlus} className="align-middle mrm-mr-0_25" /> Add Your First {replaceStringWithSynonyms('Student')}</Button>
            </Link> :
            <Link className="w-fit-content" onClick={() => setShowCoachAddUserModal(true)}>
              <Button className="d-block mrm-px-1_5 mrm-py-0_75" variant="primary"><FontAwesomeIcon icon={faPlus} className="align-middle mrm-mr-0_25" /> Add Your First {replaceStringWithSynonyms('Student')}</Button>
            </Link>
          }
          <AddUserModal
            show={showCoachAddUserModal}
            onHide={handleCoachAddUserModalHide}
            handleUserSuccess={handleNewUserSuccess}
          />
        </>
        ;
    };

    if (onboardingState.state && onboardingState.permission === false) {
      coachMessagePrompt =
        <>
          <h2 className="font-size-largest"><span role="img" aria-label="hand-wave">👋 </span> Welcome to {user && user.company_name}, {user && user.first_name}!</h2>
          <p>You don't have a {replaceStringWithSynonyms('student')}. Reach out to your admin for them to add one for you.</p>
        </>
        ;
    };
  
    if (onboardingState.state && onboardingState.hasRoadmap === false) {
      coachMessagePrompt =
        <>
          <h2 className="font-size-largest">Assign a Roadmap to <span>{assignedUsersFull.results.find(u => u.roadmaps_info.length === 0).first_name}</span></h2>
          <p>Now that you've added your first {replaceStringWithSynonyms('student')}, let's assign them to their first Roadmap.</p>
          <Link className="w-fit-content" to={{ pathname: `/manage/user/${assignedUsersFull.results.find(u => u.roadmaps_info.length === 0)?.id}/edit-roadmap`, state: { backLink: location } }}>
            <Button className="d-block mrm-px-1_5 mrm-py-0_75" variant="primary"><FontAwesomeIcon icon={faPlus} className="align-middle mrm-mr-0_25" /> Assign a Roadmap to {assignedUsersFull.results.find(u => u.roadmaps_info.length === 0).first_name}</Button>
          </Link>
        </>
        ;
    };
  
    if (onboardingState.state && onboardingState.hasRoadmap === true) {
      coachMessagePrompt =
        <>
          <h2 className="font-size-largest"><span role="img" aria-label="party">🎉</span> Review {replaceStringWithSynonyms('student')} progress</h2>
          <p className="mrm-mb-0_5">Sit tight and wait for your client's to complete assessments or action items within their Roadmap.</p>
          <p>You can also send them a <Link to="/messages/default">secure message</Link> to start a conversation.</p>
          {isMobile ?
            <Link className="w-fit-content" to="/dashboard/coach-add-user">
              <Button className="d-block mrm-px-1_5 mrm-py-0_75" variant="primary"><FontAwesomeIcon icon={faPlus} className="align-middle mrm-mr-0_25" /> Add Another {replaceStringWithSynonyms('Student')}</Button>
            </Link> :
            <Link className="w-fit-content" onClick={() => setShowCoachAddUserModal(true)}>
              <Button className="d-block mrm-px-1_5 mrm-py-0_75" variant="primary"><FontAwesomeIcon icon={faPlus} className="align-middle mrm-mr-0_25" /> Add Another {replaceStringWithSynonyms('Student')}</Button>
            </Link>
          }
          <AddUserModal
            show={showCoachAddUserModal}
            onHide={handleCoachAddUserModalHide}
            handleUserSuccess={handleNewUserSuccess}
          />
          <AddCallModal show={showAddCallModal} onHide={handleAddCallModalHide} />
        </>
    };

    return(
      <Container>
        <Card className="my-5 col-12 mrm-p-2">
          {coachMessagePrompt}
        </Card>
      </Container>
    )
  };

  return (
    <div className={clsx('dashboard-admin-page', { 'mrm-mt-1': window.location.pathname === "/dashboard/admin" && windowSize.width < 991 })}>
      <DesktopHeader>
        {/* <Container>
          <div className="desktop-page-secondary-header-wrapper mrm-p-1">
            <AdminPageHeader
              type={type}
              replaceStringWithSynonyms={replaceStringWithSynonyms}
              assignedUsers={assignedUsers}
              handleSearchChange={handleSearchChange}
              sortByOptions={sortByOptions}
              sortByValue={sortBy}
              onSort={handleSortMenuClick}
              canAddUsers={(user && canCoachAddUsers)}
            />
          </div>
        </Container> */}
      </DesktopHeader>
      <div className="d-lg-none mobile-page-container">
        {onboardingState.loaded === true ? <CoachMessagePrompt /> : ''}

        {canCoachCalls && window.location.pathname === "/dashboard/coach" && (<CoachUpcommingCalls />)}
        <AdminPageHeader
          type={type}
          replaceStringWithSynonyms={replaceStringWithSynonyms}
          assignedUsers={assignedUsers}
          assignedUsersFull={assignedUsersFull}
          handleSearchChange={handleSearchChange}
          handleSortClick={handleSortClick}
        // canAddUsers={(user && canCoachAddUsers)}
        />
        <div className="d-lg-none mrm-mb-1">
          {(user && canCoachAddUsers) && <CoachAddUser />}
        </div>
        {renderContent({ toggleShowRoadmaps: true })}
        {assignedUsers && detailMenu && (
          <ActionMenu
            show={!!detailMenu}
            onHide={handleHideDetailMenu}
            items={actionMenuItems()}
          />
        )}
        <SortMenu
          show={!!sortMenu}
          onHide={handleHideSortMenu}
          onSort={handleSortMenuClick}
          value={sortBy}
          sortByOptions={sortByOptions}
        />
      </div>

      <div className="d-none d-lg-block desktop-page-container">
      {onboardingState.loaded === true ? <CoachMessagePrompt /> : ''}

        {canCoachCalls && window.location.pathname === "/dashboard/coach" && (
          <Container>
            <Card className="px-3 my-5 col-12">
              <CoachUpcommingCalls />
            </Card>
          </Container>
        )}
        <Container className='toggle-fixed-position'>
          <div className="desktop-page-secondary-header-wrapper mrm-p-1">
            <AdminPageHeader
              type={type}
              replaceStringWithSynonyms={replaceStringWithSynonyms}
              assignedUsers={assignedUsers}
              assignedUsersFull={assignedUsersFull}
              handleSearchChange={handleSearchChange}
              sortByOptions={sortByOptions}
              sortByValue={sortBy}
              onSort={handleSortMenuClick}
              canAddUsers={(user && canCoachAddUsers)}
              handleNewUserSuccess={handleNewUserSuccess}
            />
          </div>
        </Container>
        <Container className="content-wrapper normal-width-container">
          {renderContent({ toggleShowRoadmaps: false })}
        </Container>
        <EditUserRoadmapModal show={showEditUserRoadmapModal} onHide={handleEditUserRoadmapModalHide} userId={editRoadmapUserId} />
      </div>
    </div>
  );
};

AdminPage.propTypes = {};
AdminPage.defaultProps = {};
