// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  roadmaps: {},
  stages: {},
  competencies: {},
  actionItems: {},
  selectedCompetency: null,
  fetchRoadmapStagesPending: false,
  fetchRoadmapStagesError: null,
  fetchStageCompetenciesPending: false,
  fetchStageCompetenciesError: null,
  fetchCompetencyCommentsPending: false,
  fetchCompetencyCommentsError: null,
  fetchCompetencyAssessmentsPending: false,
  fetchCompetencyAssessmentsError: null,
  fetchCompetencyGlobalActionItemsPending: false,
  fetchCompetencyGlobalActionItemsError: null,
  fetchCompetencyActionItemAssessmentsPending: false,
  fetchCompetencyActionItemAssessmentsError: null,
  setCompetencyAssessmentPending: false,
  setCompetencyAssessmentError: null,
  setActionItemDetailsPending: false,
  setActionItemDetailsError: null,
  fetchRoadmapPending: false,
  fetchRoadmapError: null,
  deleteCompetencyCommentPending: false,
  deleteCompetencyCommentError: null,
  fetchRecentCompetencyPending: false,
  fetchRecentCompetencyError: null,
  submitRecentCompetencyPending: false,
  submitRecentCompetencyError: null,
  addActionItemPending: false,
  addActionItemError: null,
  deleteActionItemPending: false,
  deleteActionItemError: null,
  addActionItemAttachmentPending: false,
  addActionItemAttachmentError: null,
  deleteActionItemAttachmentPending: false,
  deleteActionItemAttachmentError: null,
  addCompetencyAttachmentPending: false,
  addCompetencyAttachmentError: null,
  deleteCompetencyAttachmentPending: false,
  deleteCompetencyAttachmentError: null,
  fetchRoadmapCoachesPending: false,
  fetchRoadmapCoachesError: null,
  bulkAssignRoadmapCoachesPending: false,
  bulkAssignRoadmapCoachesError: null,
  addNotePending: false,
  addNoteError: null,
  fetchCompetencyNotesPending: false,
  fetchCompetencyNotesError: null,
  setActionItemAssessmentPending: false,
  setActionItemAssessmentError: null,
  approveCompetencyAssessmentPending: false,
  approveCompetencyAssessmentError: null,
  updateCompetencyAttachmentPending: false,
  updateCompetencyAttachmentError: null,
  fetchQuestionAnswersPending: false,
  fetchQuestionAnswersError: null,
  addQuestionAnswerPending: false,
  addQuestionAnswerError: null,
  updateQuestionAnswerPending: false,
  updateQuestionAnswerError: null,
  updateNotePending: false,
  updateNoteError: null,
  deleteNotePending: false,
  deleteNoteError: null,
  fetchRoadmapStagesInfinitePending: false,
  fetchRoadmapStagesInfiniteError: null,
};

export default initialState;
