export const USER_FETCH_USER_BEGIN = 'USER_FETCH_USER_BEGIN';
export const USER_FETCH_USER_SUCCESS = 'USER_FETCH_USER_SUCCESS';
export const USER_FETCH_USER_FAILURE = 'USER_FETCH_USER_FAILURE';
export const USER_FETCH_USER_DISMISS_ERROR = 'USER_FETCH_USER_DISMISS_ERROR';
export const USER_UPDATE_USER_BEGIN = 'USER_UPDATE_USER_BEGIN';
export const USER_UPDATE_USER_SUCCESS = 'USER_UPDATE_USER_SUCCESS';
export const USER_UPDATE_USER_FAILURE = 'USER_UPDATE_USER_FAILURE';
export const USER_UPDATE_USER_DISMISS_ERROR = 'USER_UPDATE_USER_DISMISS_ERROR';
export const USER_UPDATE_PROFILE_PHOTO_BEGIN = 'USER_UPDATE_PROFILE_PHOTO_BEGIN';
export const USER_UPDATE_PROFILE_PHOTO_SUCCESS = 'USER_UPDATE_PROFILE_PHOTO_SUCCESS';
export const USER_UPDATE_PROFILE_PHOTO_FAILURE = 'USER_UPDATE_PROFILE_PHOTO_FAILURE';
export const USER_UPDATE_PROFILE_PHOTO_DISMISS_ERROR = 'USER_UPDATE_PROFILE_PHOTO_DISMISS_ERROR';
export const USER_DELETE_USER_BEGIN = 'USER_DELETE_USER_BEGIN';
export const USER_DELETE_USER_SUCCESS = 'USER_DELETE_USER_SUCCESS';
export const USER_DELETE_USER_FAILURE = 'USER_DELETE_USER_FAILURE';
export const USER_DELETE_USER_DISMISS_ERROR = 'USER_DELETE_USER_DISMISS_ERROR';
export const USER_SWITCH_ACTIVE_COMPANY_BEGIN = 'USER_SWITCH_ACTIVE_COMPANY_BEGIN';
export const USER_SWITCH_ACTIVE_COMPANY_SUCCESS = 'USER_SWITCH_ACTIVE_COMPANY_SUCCESS';
export const USER_SWITCH_ACTIVE_COMPANY_FAILURE = 'USER_SWITCH_ACTIVE_COMPANY_FAILURE';
export const USER_SWITCH_ACTIVE_COMPANY_DISMISS_ERROR = 'USER_SWITCH_ACTIVE_COMPANY_DISMISS_ERROR';
export const USER_DELETE_COACH_BEGIN = 'USER_DELETE_COACH_BEGIN';
export const USER_DELETE_COACH_SUCCESS = 'USER_DELETE_COACH_SUCCESS';
export const USER_DELETE_COACH_FAILURE = 'USER_DELETE_COACH_FAILURE';
export const USER_DELETE_COACH_DISMISS_ERROR = 'USER_DELETE_COACH_DISMISS_ERROR';
export const USER_INVITE_COACH_BEGIN = 'USER_INVITE_COACH_BEGIN';
export const USER_INVITE_COACH_SUCCESS = 'USER_INVITE_COACH_SUCCESS';
export const USER_INVITE_COACH_FAILURE = 'USER_INVITE_COACH_FAILURE';
export const USER_INVITE_COACH_DISMISS_ERROR = 'USER_INVITE_COACH_DISMISS_ERROR';
export const USER_FETCH_NOTIFICATIONS_SETTINGS_BEGIN = 'USER_FETCH_NOTIFICATIONS_SETTINGS_BEGIN';
export const USER_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS = 'USER_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS';
export const USER_FETCH_NOTIFICATIONS_SETTINGS_FAILURE = 'USER_FETCH_NOTIFICATIONS_SETTINGS_FAILURE';
export const USER_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR = 'USER_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR';
export const USER_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN = 'USER_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN';
export const USER_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS = 'USER_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const USER_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE = 'USER_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE';
export const USER_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR = 'USER_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR';
export const USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_BEGIN = 'USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_BEGIN';
export const USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_SUCCESS = 'USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_SUCCESS';
export const USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_FAILURE = 'USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_FAILURE';
export const USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR = 'USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR';
export const CHANGE_PASSWORD_BEGIN = 'CHANGE_PASSWORD_BEGIN';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_DISMISS_ERROR = 'CHANGE_PASSWORD_DISMISS_ERROR';
export const SET_CHANGE_PASSWORD_ERROR = 'SET_CHANGE_PASSWORD_ERROR';
