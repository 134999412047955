import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_COACH_CALLS_BEGIN,
  DASHBOARD_COACH_CALLS_SUCCESS,
  DASHBOARD_COACH_CALLS_FAILURE,
  DASHBOARD_COACH_CALLS_DISMISS_ERROR,
} from './constants';
// import { MANAGE_UPDATE_USER_AVATAR_SUCCESS } from '../../manage/redux/constants';
import axios from 'axios';
import config from '../../../common/config';
import fp from 'lodash/fp';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function fetchCoachUpcomingCalls(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_COACH_CALLS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      // const { role, userId, search, ordering, type, page } = args
      let url = `${config.apiRootUrl}/upcoming-calls/`

      const doRequest = axios.get(url, {
        ...createAxiosConfigWithAuth(getState())
      })
      doRequest.then(
        (res) => {
          const results = { ...res.data }
          dispatch({
            type: DASHBOARD_COACH_CALLS_SUCCESS,
            data: results,
          });
          resolve(results);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_COACH_CALLS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchCoachCallsError() {
  return {
    type: DASHBOARD_COACH_CALLS_DISMISS_ERROR,
  };
}

export function useFetchCoachUpcomingCalls() {
  const dispatch = useDispatch();

  const { coachCalls, fetchCoachCallsPending, fetchCoachCallsError } = useSelector(
    state => ({
      coachCalls: state.dashboard.coachCalls,
      fetchCoachCallsPending: state.dashboard.fetchCoachCallsPending,
      fetchCoachCallsError: state.dashboard.fetchCoachCallsError,
    })
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchCoachUpcomingCalls(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchCoachCallsError());
  }, [dispatch]);

  return {
    coachCalls,
    fetchCoachCalls: boundAction,
    fetchCoachCallsPending,
    fetchCoachCallsError,
    dismissFetchCoachCallsError: boundDismissError
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_COACH_CALLS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchCoachCallsPending: true,
        fetchCoachCallsError: null,
      };

    case DASHBOARD_COACH_CALLS_SUCCESS:
      // The request is success
      const userKey = action.data.type ? action.data.type.toLowerCase() : 'coachCalls'
      // const page = action.data.page || 0
      const res = Object.values(action.data)
      const count = Object.keys(action.data).length

      return fp.compose(
        fp.set(userKey, {
          count: count,
          results: res
        }),
        fp.set('fetchCoachCallsPending', false),
        fp.set('fetchCoachCallsError', null)
      )(state)

    case DASHBOARD_COACH_CALLS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchCoachCallsPending: false,
        fetchCoachCallsError: action.data.error,
      };

    case DASHBOARD_COACH_CALLS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchCoachCallsError: null,
      };

    default:
      return state;
  }
}
