import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useFetchUser } from '../user/redux/hooks';
// import { boolean } from 'yup';

import { AddUserModal } from './CoachAddUserPage';
export default function CoachAddUser({
  isDesktop,
  handleNewUserSuccess
}) {

  const [showCoachAddUserModal, setShowCoachAddUserModal] = useState(false);

  const { user, replaceStringWithSynonyms } = useFetchUser();
  
  const handleCoachAddUserModalHide = () => {
    setShowCoachAddUserModal(false);
  };

    return (
        (true === isDesktop) ?
        (
          <>
            {user && user.features.coaches_can_add_users && 
              <Link className="text-muted" 
                onClick={() => setShowCoachAddUserModal(true)}
              >
              <FontAwesomeIcon icon={faPlus} size='xs' />
                &nbsp;Add {replaceStringWithSynonyms('Student')}
              </Link>
            }
            <AddUserModal  
              show={showCoachAddUserModal} 
              onHide={handleCoachAddUserModalHide} 
              handleUserSuccess={handleNewUserSuccess}
            />
          </>          
        )
        :
        (
        <>
        {user && user.features.coaches_can_add_users &&
          <Link className="text-muted" to="/dashboard/coach-add-user">
            <Card>
              <Card.Body className="text-center p-2">
                
                  <FontAwesomeIcon icon={faPlus} size='xs' />
                  &nbsp;Add {replaceStringWithSynonyms('Student')}
                
              </Card.Body>
            </Card>
          </Link>
        }
        </>
        )
    );
  };
  
  CoachAddUser.propTypes = {};
  CoachAddUser.defaultProps = {};