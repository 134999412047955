import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorTweaked from 'ckeditor-build-classic-tweaked';
import config from '../../common/config';
import { useFetchAuthToken } from '../home/redux/fetchAuthToken';
import PropTypes from 'prop-types';

export default function TextEditor({ data, setData }) {
  const { authToken } = useFetchAuthToken();

  return (
    <div className="common-text-editor">
      <CKEditor
        editor={ClassicEditorTweaked}
        config={{
          simpleUpload: {
            uploadUrl: `${config.apiRootUrl}/ckeditor/upload/`,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
          fontColor: {
            columns: 6,
            colors: [
              {
                color: 'hsl(0, 0%, 0%)',
                label: 'Black'
              },
              {
                color: 'hsl(0, 0%, 20%)',
                label: 'Dim grey'
              },
              {
                color: 'hsl(0, 0%, 40%)',
                label: 'Grey'
              },
              {
                color: 'hsl(0, 0%, 60%)',
                label: 'Light grey'
              },
              {
                color: 'hsl(0, 0%, 80%)',
                label: 'Lighter grey'
              },
              {
                color: 'hsl(0, 0%, 100%)',
                label: 'White',
                hasBorder: true
              },
              {
                color: 'hsl(0, 75%, 60%)',
                label: 'Red'
              },
              {
                color: 'hsl(30, 75%, 60%)',
                label: 'Orange'
              },
              {
                color: 'hsl(60, 75%, 60%)',
                label: 'Yellow'
              },
              {
                color: 'hsl(90, 75%, 60%)',
                label: 'Light green'
              },
              {
                color: 'hsl(120, 75%, 60%)',
                label: 'Green'
              },
              {
                color: 'hsl(150, 75%, 60%)',
                label: 'Aquamarine'
              },
              {
                color: 'hsl(180, 75%, 60%)',
                label: 'Turquoise'
              },
              {
                color: 'hsl(210, 75%, 60%)',
                label: 'Light blue'
              },
              {
                color: 'hsl(240, 75%, 60%)',
                label: 'Blue'
              },
              {
                color: 'hsl(270, 75%, 60%)',
                label: 'Purple'
              },
              {
                color: '#3c9dc4',
                label: 'Blue 1'
              },
              {
                color: '#60bcd8',
                label: 'Blue 2'
              },
              {
                color: '#8cc3be',
                label: 'Blue 3'
              },
              {
                color: '#C7dc69',
                label: 'Green 1'
              },
              {
                color: '#D6d9d0',
                label: 'White 1'
              },
              {
                color: '#F5efed',
                label: 'White 2'
              }
            ]
          },
          mediaEmbed: {
            previewsInData: true,
            extraProviders: [
              {
                name: 'wistia',
                // eslint-disable-next-line no-useless-escape
                url: /(^|^[^:]+:\/\/|[^\.]+\.)wistia\.com\/medias\/(\w+)/,
                html: match => {
                  const videoID = match[2];

                  return `
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://fast.wistia.net/embed/iframe/${videoID}"></iframe>
                  </div>
                  `;
                },
              },
            ],
          },
          toolbar: {
            items: [
              'heading',
              '|',
              'fontColor',
              // 'fontFamily',
              'alignment',
              '|',
              'bold',
              'italic',
              'underline',
              '|',
              'link',
              'bulletedList',
              'numberedList',
              '|',
              'indent',
              'outdent',
              '|',
              'imageUpload',
              // 'blockQuote',
              'insertTable',
              'mediaEmbed',
              'undo',
              'redo',
            ],
          },
        }}
        data={data}
        onReady={editor => {
          // console.log(Array.from(editor.ui.componentFactory.names()));
          setData(editor.getData());

          setTimeout(function() {
            editor.editing.view.focus();
          }, 0);
        }}
        onChange={(event, editor) => setData(editor.getData())}
      />
    </div>
  );
}

TextEditor.propTypes = {
  data: PropTypes.string,
  setData: PropTypes.func,
};

TextEditor.defaultProps = {};
