export const COMMON_FETCH_UPDATES_BEGIN = 'COMMON_FETCH_UPDATES_BEGIN';
export const COMMON_FETCH_UPDATES_SUCCESS = 'COMMON_FETCH_UPDATES_SUCCESS';
export const COMMON_FETCH_UPDATES_FAILURE = 'COMMON_FETCH_UPDATES_FAILURE';
export const COMMON_FETCH_UPDATES_DISMISS_ERROR = 'COMMON_FETCH_UPDATES_DISMISS_ERROR';
export const COMMON_LOGOUT = 'COMMON_LOGOUT';
export const COMMON_SET_UNREAD_MESSAGES = 'COMMON_SET_UNREAD_MESSAGES';
export const COMMON_UPDATE_UNREAD_MESSAGES = 'COMMON_UPDATE_UNREAD_MESSAGES';
export const COMMON_FETCH_AGORA_RTM_TOKEN_BEGIN = 'COMMON_FETCH_AGORA_RTM_TOKEN_BEGIN';
export const COMMON_FETCH_AGORA_RTM_TOKEN_SUCCESS = 'COMMON_FETCH_AGORA_RTM_TOKEN_SUCCESS';
export const COMMON_FETCH_AGORA_RTM_TOKEN_FAILURE = 'COMMON_FETCH_AGORA_RTM_TOKEN_FAILURE';
export const COMMON_FETCH_AGORA_RTM_TOKEN_DISMISS_ERROR = 'COMMON_FETCH_AGORA_RTM_TOKEN_DISMISS_ERROR';
