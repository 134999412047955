import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import axios from 'axios';
import {
  MESSAGES_DELETE_GROUP_CHAT_BEGIN,
  MESSAGES_DELETE_GROUP_CHAT_SUCCESS,
  MESSAGES_DELETE_GROUP_CHAT_FAILURE,
  MESSAGES_DELETE_GROUP_CHAT_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function deleteGroupChat(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: MESSAGES_DELETE_GROUP_CHAT_BEGIN,
    });

    const { chatId } = args;

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.delete(
        `${config.apiRootUrl}/messages/groups/${chatId}/`,
        createAxiosConfigWithAuth(getState())
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: MESSAGES_DELETE_GROUP_CHAT_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MESSAGES_DELETE_GROUP_CHAT_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissDeleteGroupChatError() {
  return {
    type: MESSAGES_DELETE_GROUP_CHAT_DISMISS_ERROR,
  };
}

export function useDeleteGroupChat() {
  const dispatch = useDispatch();

  const { deleteGroupChatPending, deleteGroupChatError } = useSelector(
    state => ({
      deleteGroupChatPending: state.messages.deleteGroupChatPending,
      deleteGroupChatError: state.messages.deleteGroupChatError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(deleteGroupChat(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissDeleteGroupChatError());
  }, [dispatch]);

  return {
    deleteGroupChat: boundAction,
    deleteGroupChatPending,
    deleteGroupChatError,
    dismissDeleteGroupChatError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MESSAGES_DELETE_GROUP_CHAT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        deleteGroupChatPending: true,
        deleteGroupChatError: null,
      };

    case MESSAGES_DELETE_GROUP_CHAT_SUCCESS:
      // The request is success
      return {
        ...state,
        deleteGroupChatPending: false,
        deleteGroupChatError: null,
      };

    case MESSAGES_DELETE_GROUP_CHAT_FAILURE:
      // The request is failed
      return {
        ...state,
        deleteGroupChatPending: false,
        deleteGroupChatError: action.data.error,
      };

    case MESSAGES_DELETE_GROUP_CHAT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        deleteGroupChatError: null,
      };

    default:
      return state;
  }
}
