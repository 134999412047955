import React, { useEffect, useCallback, useState } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight, faPlus } from '@fortawesome/pro-regular-svg-icons';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useUnauthorizedErrorHandler } from '../../common/apiHelpers';
import { useFetchUpdates } from '../common/redux/hooks';
import usePagination from '../common/usePagination';

import { useFetchRoadmaps } from './redux/hooks';
import { useFetchUser } from '../user/redux/hooks';
import { useFetchAuthToken } from '../home/redux/hooks';
import { InviteCoachModal } from '../user';
import { DesktopHeader, Loader, UserAvatar } from '../common';

import UserUpcomingCalls from './UserUpcomingCalls';

const Roadmap = ({ data, unseenRoadmaps, ...other }) => (
  <Card className="mrm-mb-1 mrm-p-0_75" {...other} >
    <Row className="align-items-center" noGutters>
      {/* roadmap.icon */}
      {data.icon ? (
        <Card.Img alt={data.title} src={data.icon} />
      ) : (
        <FontAwesomeIcon className="default-roadmap-thumbnail mrm-p-0_75" icon={faMap} />
      )}
      <Col>
        <Card.Body>
          {/* roadmap.title */}
          {unseenRoadmaps?.indexOf(data.id) >= 0 && (
            <div>
              <span className="dot" />
              <span className="small"> New unseen activity</span>
            </div>
          )}
          <Card.Title>{data.title}</Card.Title>
          <Card.Text as="div">
            {/* roadmap.stats.total_progress */}
            {data.stats.total_progress > 0 && (
              <div className="progress">
                <div
                  style={{ width: `${data.stats.total_progress}%` }}
                  role="progressbar"
                  className="progress-bar"
                />
              </div>
            )}

            {data.stats.total_progress > 0 && (
              <span className="progress-text">{data.stats.total_progress.toFixed()}% complete</span>
            )}

            {data.stats.total_progress === 0 && (
              <span className="start-roadmap">Start Roadmap</span>
            )}

            <div className="assigned-coaches">
              {data.assigned_coaches.map(ar => (
                <UserAvatar size='xs' user={ar} key={ar.id} className="mrm-mr-0_25" />
              ))}
            </div>
          </Card.Text>
        </Card.Body>
      </Col>
      <FontAwesomeIcon icon={faChevronRight} size='sm' className="d-lg-none" />
    </Row>
  </Card>
)

export default function RoadmapsPage() {
  const { roadmaps, fetchRoadmapsPending, fetchRoadmaps } = useFetchRoadmaps();
  const { user, replaceStringWithSynonyms } = useFetchUser();
  const { userApproved } = useFetchAuthToken();
  const { updates } = useFetchUpdates();
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();

  usePagination({
    fetchAction: fetchRoadmaps,
    actionArgs: {
      assignedCoaches: true,
      asStudent: true,
    },
    requestNextPage: () => roadmaps && roadmaps.next && !fetchRoadmapsPending
  })

  // Initial render
  useEffect(() => {
    fetchRoadmaps({ assignedCoaches: true, asStudent: true }).catch(unauthorizedErrorHandler);
  }, [fetchRoadmaps, unauthorizedErrorHandler]);

  const showInviteCoachButton = userApproved && roadmaps && roadmaps.length < 1 &&
    user && user.coaches.length === 0 && user.features.can_assign_coach && !user.features.can_assign_roadmaps;

  const [showInviteCoachModal, setShowInviteCoachModal] = useState(false);

  const handleInviteCoachClick = useCallback(() => setShowInviteCoachModal(true), []);

  const handleInviteCoachModalHide = useCallback(() => setShowInviteCoachModal(false), []);

  const canCoachCalls = user && user.features.coaches_can_add_calls

  const currentUserIsCoachOrAdmin = user && (user.groups.includes('Admin') || user.groups.includes('Coach'));

  if (!roadmaps || !user) {
    return <Loader />
  }

  return (
    <div className="dashboard-roadmaps-page welcome container-lg">
      <DesktopHeader />
      <Row>
        <Col xs={12} className="d-lg-none">
          {canCoachCalls && (<UserUpcomingCalls />)}
          {roadmaps.results.length > 0 &&
            <h2 className="text-left mrm-my-1">My Roadmaps ({roadmaps.results.length})</h2>
          }
          {user && roadmaps.results.length === 0 && user.features.can_assign_roadmaps && !user.groups.includes('Coach') && !user.groups.includes('Admin') &&
            <Card className="mrm-p-2 mrm-mt-3">
              <h2 className="text-left font-size-largest"><span role="img" aria-label="hand-wave">👋 </span> Welcome to {user && user.company_name}, {user && user.first_name}!</h2>
              <p className="text-left">We are excited you are here. Add your first Roadmap to get started.</p> 
              <Link className="w-fit-content" to='/dashboard/roadmap-library'>
                <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="mrm-mr-0_25" size='sm' />
                   Add A Roadmap
                </Button>
              </Link>
            </Card>
          }
          {user && roadmaps.results.length === 0 && user.features.can_assign_roadmaps && currentUserIsCoachOrAdmin &&
            <Card className="mrm-p-2 mrm-mt-0_5">
              <h2 className="text-left font-size-largest">Your Roadmaps</h2>
              <p className="text-left">If you are working on any of your own Roadmaps for yourself, this is where they will appear.</p> 
              <Link className="w-fit-content" to='/dashboard/roadmap-library'>
                <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="mrm-mr-0_25" size='sm' />
                   Add A Roadmap
                </Button>
              </Link>
            </Card>
          }
          {user && roadmaps.results.length === 0 && user.coaches.length > 0 && !user.features.can_assign_roadmaps && !user.groups.includes('Coach') && !user.groups.includes('Admin') &&
            <Card className="mrm-p-2 mrm-mt-3">
              <h2 className="text-left font-size-largest"><span role="img" aria-label="hand-wave">👋 </span> Welcome to {user && user.company_name}, {user && user.first_name}!</h2>
              <p className="text-left">Your {replaceStringWithSynonyms('coach')} has not assigned a Roadmap for you to work on yet. While you wait, send a message to your {replaceStringWithSynonyms('coach')}.</p> 
              <Link className="w-fit-content" to={{pathname: `/messages/default/`}}>
                <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="primary">Go to messages</Button>
              </Link>
            </Card>
          }
          {user && roadmaps.results.length === 0 && user.coaches.length === 0 && !user.features.can_assign_roadmaps && !user.groups.includes('Coach') && !user.groups.includes('Admin') &&
            <Card className="mrm-p-2 mrm-mt-3">
              <h2 className="text-left font-size-largest"><span role="img" aria-label="hand-wave">👋 </span> Welcome to {user && user.company_name}, {user && user.first_name}!</h2>
              <p className="text-left">You don't have a {replaceStringWithSynonyms('coach')} or a Roadmap yet. Contact your admin for access. You will recieve an email notification once recieve a Roadmap or coach.</p> 
            </Card>
          }
          {user && roadmaps.results.length === 0 && !user.features.can_assign_roadmaps && currentUserIsCoachOrAdmin &&
            <Card className="mrm-p-2 mrm-mt-0_5">
              <h2 className="text-left font-size-largest">Your Roadmaps</h2>
              <p className="text-left">If you are working on any of your own Roadmaps for yourself, this is where they will appear.</p> 
              {user && user.groups.includes('Coach') && !user.groups.includes('Admin') && 
                <Link className="w-fit-content" to={{pathname: `/dashboard/coach/`}}>
                  <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="gray">Back to coach dashboard</Button>
                </Link>
              }
              {user && user.groups.includes('Admin') && 
                <Link className="w-fit-content" to={{pathname: `/dashboard/admin/`}}>
                  <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="gray">Back to admin dashboard</Button>
                </Link>
              }
            </Card>
          }
        </Col>
        <Col xs={12} className="text-center d-none d-lg-block desktop-welcome">
          {canCoachCalls && (<UserUpcomingCalls />)}
          {roadmaps.results.length > 0 &&
            <h2 className="text-left mrm-my-1">My Roadmaps ({roadmaps.results.length})</h2>
          }
          {user && roadmaps.results.length === 0 && user.features.can_assign_roadmaps && !user.groups.includes('Coach') && !user.groups.includes('Admin') &&
            <Card className="mrm-p-2">
              <h2 className="text-left font-size-largest"><span role="img" aria-label="hand-wave">👋 </span> Welcome to {user && user.company_name}, {user && user.first_name}!</h2>
              <p className="text-left">We are excited you are here. Add your first Roadmap to get started.</p> 
              <Link className="w-fit-content" to='/dashboard/roadmap-library'>
                <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="mrm-mr-0_25" size='sm' />
                   Add A Roadmap
                </Button>
              </Link>
            </Card>
          }
          {user && roadmaps.results.length === 0 && user.features.can_assign_roadmaps && currentUserIsCoachOrAdmin &&
            <Card className="mrm-p-2">
              <h2 className="text-left font-size-largest">Your Roadmaps</h2>
              <p className="text-left">If you are working on any of your own Roadmaps for yourself, this is where they will appear.</p> 
              <Link className="w-fit-content" to='/dashboard/roadmap-library'>
                <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="mrm-mr-0_25" size='sm' />
                   Add A Roadmap
                </Button>
              </Link>
            </Card>
          }
          {user && roadmaps.results.length === 0 && user.coaches.length > 0 && !user.features.can_assign_roadmaps && !user.groups.includes('Coach') && !user.groups.includes('Admin') &&
            <Card className="mrm-p-2">
              <h2 className="text-left font-size-largest"><span role="img" aria-label="hand-wave">👋 </span> Welcome to {user && user.company_name}, {user && user.first_name}!</h2>
              <p className="text-left">Your {replaceStringWithSynonyms('coach')} has not assigned a Roadmap for you to work on yet. While you wait, send a message to your {replaceStringWithSynonyms('coach')}.</p> 
              <Link className="w-fit-content" to={{pathname: `/messages/default/`}}>
                <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="primary">Go to messages</Button>
              </Link>
            </Card>
          }
          {user && roadmaps.results.length === 0 && user.coaches.length === 0 && !user.features.can_assign_roadmaps && !user.groups.includes('Coach') && !user.groups.includes('Admin') &&
            <Card className="mrm-p-2">
              <h2 className="text-left font-size-largest"><span role="img" aria-label="hand-wave">👋 </span> Welcome to {user && user.company_name}, {user && user.first_name}!</h2>
              <p className="text-left">You don't have a {replaceStringWithSynonyms('coach')} or a Roadmap yet. Contact your admin for access. You will recieve an email notification once recieve a Roadmap or coach.</p> 
            </Card>
          }
          {user && roadmaps.results.length === 0 && !user.features.can_assign_roadmaps && currentUserIsCoachOrAdmin &&
            <Card className="mrm-p-2">
              <h2 className="text-left font-size-largest">Your Roadmaps</h2>
              <p className="text-left">If you are working on any of your own Roadmaps for yourself, this is where they will appear.</p> 
              {user && user.groups.includes('Coach') && !user.groups.includes('Admin') && 
                <Link className="w-fit-content" to={{pathname: `/dashboard/coach/`}}>
                  <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="gray">Back to coach dashboard</Button>
                </Link>
              }
              {user && user.groups.includes('Admin') && 
                <Link className="w-fit-content" to={{pathname: `/dashboard/admin/`}}>
                  <Button className="inline-block mrm-px-1_5 mrm-py-0_75" variant="gray">Back to admin dashboard</Button>
                </Link>
              }
            </Card>
          }
        </Col>
        <Col xs={12}>
          {showInviteCoachButton && <Button variant="primary" className="btn-100" onClick={handleInviteCoachClick}>
            Invite a Coach
          </Button>}
          {roadmaps.results.map(r => (
            <Link to={`/roadmap/${r.id}`} key={r.id} className="no-format">
              <Roadmap data={r} unseenRoadmaps={updates?.unseen_activity?.[user.id]?.roadmaps} />
            </Link>
          ))}
        </Col>
      </Row>
      {user.features.can_assign_roadmaps && roadmaps.results.length > 0 && <Row className="justify-content-center">
        <Col className="add-roadmap" xs={12} lg={4}>
          <Link to='/dashboard/roadmap-library'>
            <Button variant={roadmaps.length === 0 ? 'primary' : 'white' } className="btn-100">
              <FontAwesomeIcon icon={faPlus} className="mrm-mr-0_25" size='sm' />
              {roadmaps.length === 0 ? 'Add A Roadmap' : 'Add Additional Roadmaps' }
            </Button>
          </Link>
        </Col>
      </Row>}
      <InviteCoachModal
        show={showInviteCoachModal}
        onHide={handleInviteCoachModalHide}
      />
    </div>
  );
}

RoadmapsPage.propTypes = {};
RoadmapsPage.defaultProps = {};
