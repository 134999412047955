import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Loader } from '../common';
import dayjs from 'dayjs';
import { useFetchCoachUpcomingCalls } from './redux/hooks';
import clsx from 'clsx';
import { AddCallModal } from './CoachAddCallsPage';
import { EditCallModal } from './CoachEditCallsPage';
import useWindowResize from '../common/useWindowResize'


function AdminPageCallHeader(
  {
    coachCalls,
    handleViewAll,
    fetchCoachCalls
  }
) {
  const [isOpen, setIsOpen] = useState(false);

  const handleViewAllChange = useCallback(event => {
    if (isOpen) {
      handleViewAll(4)
      setIsOpen(!isOpen)
    } else {
      handleViewAll(undefined)
      setIsOpen(!isOpen)
    }
  }, [handleViewAll, isOpen])

  const viewAllLabel = coachCalls && coachCalls.count <= 4 ? "" : "View all"
  const colapseLabel = coachCalls && coachCalls.count <= 4 ? "" : "Collapse"

  const [showAddCallModal, setShowAddCallModal] = useState(false);

  const handleAddCallModalHide = useCallback((createdRoadmapId) => {
    setShowAddCallModal(false);
    fetchCoachCalls();
  }, [fetchCoachCalls]);

  return (
  <div className="d-flex flex-column mrm-mb-1">
    <div className="d-flex flex-row justify-content-between justify-content-lg-start  align-items-center">
      <h2 className="dashboard-title theme-text-normal mrm-mb-1 mrm-mt-1">
        Upcoming Calls&nbsp;
        {coachCalls && <strong>({coachCalls.count})</strong>}
      </h2>
      {coachCalls && coachCalls.count > 4 &&
        <div className="d-lg-none">
          <Link
            className="text-muted" href="/dashboard/upcoming-calls"
            to="/dashboard/upcoming-calls"
          >
          View all
          </Link>
      </div>
      }
      <div className="d-none d-lg-block ml-lg-3">
        <Link
          className="text-muted" onClick={() => {handleViewAllChange()}}
        >
          {isOpen ? colapseLabel : viewAllLabel}
        </Link>
      </div>
      <div className="d-none d-lg-block ml-auto">
        <Link 
          className="text-muted"
          onClick={() => setShowAddCallModal(true)}
          >
          <FontAwesomeIcon icon={faPlus} size='xs' />
          &nbsp;Add Call
        </Link>
      </div>
    </div>
    <div>
      <Link className="text-muted" to="/dashboard/coach-add-calls">
        <Card className="d-lg-none">
          <Card.Body className="text-center p-2">
            <FontAwesomeIcon icon={faPlus} size='xs' />
            &nbsp;Add Call
          </Card.Body>
        </Card>
      </Link>
    </div>
    <AddCallModal show={showAddCallModal} onHide={handleAddCallModalHide} />
  </div>);
}

const renderCallContent = (coachCalls, fetchCoachCallsPending, fetchCoachCall, showAll ) => {
  if (!coachCalls) {
    return <Loader />
  } else if (coachCalls.results.length === 0) {
    return <p className="no-data d-none d-lg-block">No upcoming calls.</p>
  } else {
    const lastCoachCalls = coachCalls.results.length <= 4 ? coachCalls.results.sort(function(x, y){return x.timestamp - y.timestamp;}) : coachCalls.results.slice(coachCalls.results.length - showAll).reverse()
    return (
      <>
        {lastCoachCalls.map(u => (
          <CallCard
            key={u.id}
            call={u}
            callName={u.call_name}
            timestamp={u.timestamp}
            meetingLink={u.meeting_link}
            fetchCoachCall={fetchCoachCall}
          />
        ))}
        {fetchCoachCallsPending && (
          <Loader />
        )}
      </>
    )
  }
}

const CallCard = (
  {
    call,
    callName,
    timestamp,
    participants,
    meetingLink,
    weekly,
    fetchCoachCall
  }
) => {
  const [showEditCallModal, setShowEditCallModal] = useState(false);
  const handleEditCallModalHide = useCallback((createdRoadmapId) => {
    setShowEditCallModal(false);
    fetchCoachCall();
  }, [fetchCoachCall]);
  var calendar = require('dayjs/plugin/calendar')
  dayjs.extend(calendar)
  return (
    <div className="col-12 col-lg-6">
      <a href={meetingLink} target="_blank" rel="noopener noreferrer" className="text-reset">
        <Card className={clsx('mrm-mb-1 upcomingcalls-card coach-upcomingcalls-card')}>
          <Card.Body className="position-relative mrm-py-0_75 mrm-px-1">
            <Card.Title className="h6 text-decoration-none font-weight-bold">
              <FontAwesomeIcon icon={faPhone} size='xs' className='mr-2' />
              {callName}
            </Card.Title>
            { call.recours_weekly ? 
              <div className="d-flex">
                <span className="item-count gray-count">
                  <FontAwesomeIcon icon={faCalendarWeek} size='xs' />
                  &nbsp;
                  {dayjs(timestamp).calendar(null, {
                    sameDay: '[Today] @ h:mm A (Every ddd)', // The same day ( Today @ 2:30 AM )
                    nextDay: '[Tomorrow] @ h:mm A (Every ddd)', // The next day ( Tomorrow @ 2:30 AM )
                    nextWeek: 'Every dddd @ h:mm A ', // The next week ( Sunday @ 2:30 AM )
                    sameElse: 'Every dddd @ h:mm A ' // Everything else ( July 15 @ 2:00pm )
                  })}
                </span>
              </div>
              :
              <div className="d-flex">
                <span className="item-count gray-count">
                  <FontAwesomeIcon icon={faCalendarWeek} size='xs' />
                  &nbsp;
                  {dayjs(timestamp).calendar(null, {
                    sameDay: '[Today] @ h:mm A', // The same day ( Today @ 2:30 AM )
                    nextDay: '[Tomorrow] @ h:mm A', // The next day ( Tomorrow @ 2:30 AM )
                    nextWeek: 'dddd @ h:mm A', // The next week ( Sunday @ 2:30 AM )
                    sameElse: 'MMMM D @ h:mm A' // Everything else ( July 15 @ 2:00pm )
                  })}
                </span>
              </div>
            }
            <Link
              className="more-btn-desktop d-lg-none"
              to={`/dashboard/coach-edit-calls/${call.id}`}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <Link
              className="more-btn-desktop d-none d-lg-block"
              onClick={() => setShowEditCallModal(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </Card.Body>
        </Card>
      </a>
      <EditCallModal callId={call.id} show={showEditCallModal} onHide={handleEditCallModalHide} />
    </div>
  )
}

export default function CoachUpcommingCalls() {
  const { coachCalls, fetchCoachCallsPending, fetchCoachCalls } = useFetchCoachUpcomingCalls();
  const [showAll, setshowAll] = useState(4)
  const windowSize = useWindowResize()
  useEffect(() => {
    fetchCoachCalls();
  }, [fetchCoachCalls])
  return (
    <div className="dashboard-coach-add-calls">
      <AdminPageCallHeader coachCalls={coachCalls} handleViewAll={setshowAll} fetchCoachCalls={fetchCoachCalls}/>
      <Container className="d-contents">
        <Row className={clsx('mrm-mb-0_5' , {'mrm-mb-1': windowSize.width < 991})}>
          {renderCallContent(coachCalls, fetchCoachCallsPending, fetchCoachCalls, showAll, setshowAll )}
        </Row>
      </Container>
    </div>
  );
};

CoachUpcommingCalls.propTypes = {};
CoachUpcommingCalls.defaultProps = {};
