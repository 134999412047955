import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_USER_CALLS_BEGIN,
  DASHBOARD_USER_CALLS_SUCCESS,
  DASHBOARD_USER_CALLS_FAILURE,
  DASHBOARD_USER_CALLS_DISMISS_ERROR,
} from './constants';
// import { MANAGE_UPDATE_USER_AVATAR_SUCCESS } from '../../manage/redux/constants';
import axios from 'axios';
import config from '../../../common/config';
import fp from 'lodash/fp';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function fetchUserUpcomingCalls(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_USER_CALLS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      // const { role, userId, search, ordering, type, page } = args
      let url = `${config.apiRootUrl}/upcoming-calls/user_upcoming_calls/`

      const doRequest = axios.get(url, {
        ...createAxiosConfigWithAuth(getState())
      })
      doRequest.then(
        (res) => {
          const results = { ...res.data }
          dispatch({
            type: DASHBOARD_USER_CALLS_SUCCESS,
            data: results,
          });
          resolve(results);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_USER_CALLS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchUserCallsError() {
  return {
    type: DASHBOARD_USER_CALLS_DISMISS_ERROR,
  };
}

export function useFetchUserUpcomingCalls() {
  const dispatch = useDispatch();

  const { userCalls, fetchUserCallsPending, fetchUserCallsError } = useSelector(
    state => ({
      userCalls: state.dashboard.userCalls,
      fetchUserCallsPending: state.dashboard.fetchUserCallsPending,
      fetchUserCallsError: state.dashboard.fetchUserCallsError,
    })
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchUserUpcomingCalls(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchUserCallsError());
  }, [dispatch]);

  return {
    userCalls,
    fetchUserUpcomingCalls: boundAction,
    fetchUserCallsPending,
    fetchUserCallsError,
    dismissFetchUserCallsError: boundDismissError
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_USER_CALLS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchUserCallsPending: true,
        fetchUserCallsError: null,
      };

    case DASHBOARD_USER_CALLS_SUCCESS:
      // The request is success
      const userKey = action.data.type ? action.data.type.toLowerCase() : 'userCalls'
      // const page = action.data.page || 0
      const res = action.data ? Object.values(action.data) : []
      const count = action.data ? Object.keys(action.data).length : 0

      return fp.compose(
        fp.set(userKey, {
          count: count,
          results: res
        }),
        fp.set('fetchUserCallsPending', false),
        fp.set('fetchUserCallsError', null)
      )(state)

    case DASHBOARD_USER_CALLS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchUserCallsPending: false,
        fetchUserCallsError: action.data.error,
      };

    case DASHBOARD_USER_CALLS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchUserCallsError: null,
      };

    default:
      return state;
  }
}
