import React, { useCallback, useEffect } from 'react';
import { Header, Loader } from '../common';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { useFetchUser } from '../user/redux/hooks';
import { useFetchUserUpcomingCalls } from './redux/hooks';
import { useFetchCoachUpcomingCalls } from './redux/hooks';
import { faChevronLeft, faEdit, faPlus } from '@fortawesome/pro-regular-svg-icons';

export default function UpcomingCallsPage() {
  const { user } = useFetchUser()
  const userGroups = user ? user.groups : []
  const isCoach = (user ? !!user.features.coaches_can_add_calls : false) && userGroups.includes('Coach');
  const defaultBackLink = `/dashboard/`;
  
  const renderBackLink = useCallback(
    effectiveBackLink => (
      <Link to={defaultBackLink}>
        <Button className="btn-cancel text-gray" variant="light">
          <FontAwesomeIcon icon={faChevronLeft} size='xs' className='mr-2' />
          &nbsp;Back
        </Button>
      </Link>
    ),
    [defaultBackLink],
  );

  const renderSaveButton = () => (
      <Link to="/dashboard/coach-add-calls">
        <Button
          className="btn-save"
          variant="link"
        >
          Add
        </Button>
      </Link>
  );

  const UserCallCard = (
    {
      user,
      callName,
      timestamp,
      participants,
      meetingLink,
      weekly
    }
  ) => {
    var calendar = require('dayjs/plugin/calendar')
    dayjs.extend(calendar)
    return (
        <Card
          className={clsx('mrm-mb-1 student-card')}
        >
          <Card className="position-relative mrm-py-0_75 mrm-px-1">
            <Card.Title className="h6 text-decoration-none font-weight-bold">
              <a href={meetingLink} target="_blank" rel="noopener noreferrer" className="text-reset">
                <FontAwesomeIcon icon={faPhone} size='xs' className='mr-2' />
                {callName}
              </a>
            </Card.Title>
            <div className="d-flex">
              <span className="item-count gray-count">
                <FontAwesomeIcon icon={faCalendarWeek} size='xs' />
                &nbsp;
                {dayjs(timestamp).calendar(null, {
                  sameDay: '[Today] @ h:mm A', // The same day ( Today @ 2:30 AM )
                  nextDay: '[Tomorrow] @ h:mm A', // The next day ( Tomorrow @ 2:30 AM )
                  nextWeek: 'dddd @ h:mm A', // The next week ( Sunday @ 2:30 AM )
                  sameElse: 'MMMM D @ h:mm A' // Everything else ( July 15 @ 2:00pm )
                })}
              </span>
            </div>
          </Card>
        </Card>
    )
  }

  const CoachCallCard = (
    {
      user,
      callName,
      timestamp,
      participants,
      meetingLink,
      weekly
    }
  ) => {
    var calendar = require('dayjs/plugin/calendar')
    dayjs.extend(calendar)
    return (
        <Card
          className={clsx('mrm-mb-1 student-card')}
        >
          <Card.Body className="position-relative mrm-py-0_75 mrm-px-1">
            <Card.Title className="h6 text-decoration-none font-weight-bold">
              <a href={meetingLink} target="_blank" rel="noopener noreferrer" className="text-reset">
                <FontAwesomeIcon icon={faPhone} size='xs' className='mr-2' />
                {callName}
              </a>
            </Card.Title>
            <div className="d-flex">
              <span className="item-count gray-count">
                <FontAwesomeIcon icon={faCalendarWeek} size='xs' />
                &nbsp;
                {dayjs(timestamp).calendar(null, {
                  sameDay: '[Today] @ h:mm A', // The same day ( Today @ 2:30 AM )
                  nextDay: '[Tomorrow] @ h:mm A', // The next day ( Tomorrow @ 2:30 AM )
                  nextWeek: 'dddd @ h:mm A', // The next week ( Sunday @ 2:30 AM )
                  sameElse: 'MMMM D @ h:mm A' // Everything else ( July 15 @ 2:00pm )
                })}
              </span>
            </div>
            <Link
              className="more-btn-desktop"
              to={`/dashboard/coach-edit-calls/${user.id}`}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </Card.Body>
        </Card>
    )
  }

  const renderCallContent = () => {
    if(!isCoach) {
      if (!userCalls) {
        return <Loader />
      } else if (userCalls.count === 0) {
        return <p className="no-data">No upcoming calls.</p>
      } else {
        const lastUserCalls = userCalls.results.sort(function(x, y){return x.timestamp - y.timestamp;})
        return (
          <>
            {lastUserCalls.map(u => (
              <UserCallCard
                key={u.id}
                user={u}
                callName={u.call_name}
                timestamp={u.timestamp}
                meetingLink={u.meeting_link}
              />
            ))}
            {fetchUserUpcomingCallsPending && (
              <Loader />
            )}
          </>
        )
      }
    } else {
      if (!coachCalls) {
        return <Loader />
      } else if (coachCalls.count === 0) {
        return <p className="no-data">No upcoming calls.</p>
      } else {
        const lastCoachCalls = coachCalls.results.sort(function(x, y){return x.timestamp - y.timestamp;})
        return (
          <>
            {lastCoachCalls.map(u => (
              <CoachCallCard
                key={u.id}
                user={u}
                callName={u.call_name}
                timestamp={u.timestamp}
                meetingLink={u.meeting_link}
              />
            ))}
            {fetchCoachUpcomingCallsPending && (
              <Loader />
            )}
          </>
        )
      }
    }

  }

  const { userCalls, fetchUserUpcomingCallsPending, fetchUserUpcomingCalls } = useFetchUserUpcomingCalls();
  const { coachCalls, fetchCoachUpcomingCallsPending, fetchCoachCalls } = useFetchCoachUpcomingCalls();

  useEffect(() => {
    isCoach ? fetchCoachCalls() : fetchUserUpcomingCalls()
  }, [isCoach, fetchUserUpcomingCalls, fetchCoachCalls])

  const callsCounter = isCoach ? coachCalls.count : userCalls.count

  return (
    <div className="dashboard-coach-add-calls-page">

      <Header
        icon="back"
        title={`All Calls (${callsCounter})`}
        renderThirdColumn={isCoach ? renderSaveButton : ""}
        thirdColumnClass="text-right"
        colSizes={['auto', undefined, 'auto']}
        border
        renderBackLink={renderBackLink}
        defaultBackLink={defaultBackLink}
      >
      </Header>

      <Container className="mt-3">
        {isCoach && (
          <Row>
            <Col>
              <Card className="mb-3">
                <Card.Body className="text-center p-2">
                  <Link to="/dashboard/coach-add-calls">
                    <FontAwesomeIcon icon={faPlus} size='xs' />
                    &nbsp;Add New Call
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {renderCallContent()}
          </Col>
        </Row>
      </Container>

    </div>
  );
}