export const ROADMAP_FETCH_ROADMAP_STAGES_BEGIN = 'ROADMAP_FETCH_ROADMAP_STAGES_BEGIN';
export const ROADMAP_FETCH_ROADMAP_STAGES_SUCCESS = 'ROADMAP_FETCH_ROADMAP_STAGES_SUCCESS';
export const ROADMAP_FETCH_ROADMAP_STAGES_FAILURE = 'ROADMAP_FETCH_ROADMAP_STAGES_FAILURE';
export const ROADMAP_FETCH_ROADMAP_STAGES_DISMISS_ERROR =
  'ROADMAP_FETCH_ROADMAP_STAGES_DISMISS_ERROR';
export const ROADMAP_FETCH_STAGE_COMPETENCIES_BEGIN = 'ROADMAP_FETCH_STAGE_COMPETENCIES_BEGIN';
export const ROADMAP_FETCH_STAGE_COMPETENCIES_SUCCESS =
  'ROADMAP_FETCH_STAGE_COMPETENCIES_SUCCESS';
export const ROADMAP_FETCH_STAGE_COMPETENCIES_FAILURE =
  'ROADMAP_FETCH_STAGE_COMPETENCIES_FAILURE';
export const ROADMAP_FETCH_STAGE_COMPETENCIES_DISMISS_ERROR =
  'ROADMAP_FETCH_STAGE_COMPETENCIES_DISMISS_ERROR';
export const ROADMAP_FETCH_COMPETENCY_COMMENTS_BEGIN = 'ROADMAP_FETCH_COMPETENCY_COMMENTS_BEGIN';
export const ROADMAP_FETCH_COMPETENCY_COMMENTS_SUCCESS =
  'ROADMAP_FETCH_COMPETENCY_COMMENTS_SUCCESS';
export const ROADMAP_FETCH_COMPETENCY_COMMENTS_ADD =
'ROADMAP_FETCH_COMPETENCY_COMMENTS_ADD';
export const ROADMAP_FETCH_COMPETENCY_COMMENTS_REMOVE =
'ROADMAP_FETCH_COMPETENCY_COMMENTS_REMOVE';
export const ROADMAP_FETCH_COMPETENCY_COMMENTS_FAILURE =
  'ROADMAP_FETCH_COMPETENCY_COMMENTS_FAILURE';
export const ROADMAP_FETCH_COMPETENCY_COMMENTS_DISMISS_ERROR =
  'ROADMAP_FETCH_COMPETENCY_COMMENTS_DISMISS_ERROR';
export const ROADMAP_FETCH_COMPETENCY_ASSESSMENTS_BEGIN =
  'ROADMAP_FETCH_COMPETENCY_ASSESSMENTS_BEGIN';
export const ROADMAP_FETCH_COMPETENCY_ASSESSMENTS_SUCCESS =
  'ROADMAP_FETCH_COMPETENCY_ASSESSMENTS_SUCCESS';
export const ROADMAP_FETCH_COMPETENCY_ASSESSMENTS_FAILURE =
  'ROADMAP_FETCH_COMPETENCY_ASSESSMENTS_FAILURE';
export const ROADMAP_FETCH_COMPETENCY_ASSESSMENTS_DISMISS_ERROR =
  'ROADMAP_FETCH_COMPETENCY_ASSESSMENTS_DISMISS_ERROR';
export const ROADMAP_FETCH_COMPETENCY_GLOBAL_ACTION_ITEMS_BEGIN =
  'ROADMAP_FETCH_COMPETENCY_GLOBAL_ACTION_ITEMS_BEGIN';
export const ROADMAP_FETCH_COMPETENCY_GLOBAL_ACTION_ITEMS_SUCCESS =
  'ROADMAP_FETCH_COMPETENCY_GLOBAL_ACTION_ITEMS_SUCCESS';
export const ROADMAP_FETCH_COMPETENCY_GLOBAL_ACTION_ITEMS_FAILURE =
  'ROADMAP_FETCH_COMPETENCY_GLOBAL_ACTION_ITEMS_FAILURE';
export const ROADMAP_FETCH_COMPETENCY_GLOBAL_ACTION_ITEMS_DISMISS_ERROR =
  'ROADMAP_FETCH_COMPETENCY_GLOBAL_ACTION_ITEMS_DISMISS_ERROR';
export const ROADMAP_FETCH_COMPETENCY_ACTION_ITEM_ASSESSMENTS_BEGIN =
  'ROADMAP_FETCH_COMPETENCY_ACTION_ITEM_ASSESSMENTS_BEGIN';
export const ROADMAP_FETCH_COMPETENCY_ACTION_ITEM_ASSESSMENTS_SUCCESS =
  'ROADMAP_FETCH_COMPETENCY_ACTION_ITEM_ASSESSMENTS_SUCCESS';
export const ROADMAP_FETCH_COMPETENCY_ACTION_ITEM_ASSESSMENTS_FAILURE =
  'ROADMAP_FETCH_COMPETENCY_ACTION_ITEM_ASSESSMENTS_FAILURE';
export const ROADMAP_FETCH_COMPETENCY_ACTION_ITEM_ASSESSMENTS_DISMISS_ERROR =
  'ROADMAP_FETCH_COMPETENCY_ACTION_ITEM_ASSESSMENTS_DISMISS_ERROR';
export const ROADMAP_SET_COMPETENCY_ASSESSMENT_BEGIN = 'ROADMAP_SET_COMPETENCY_ASSESSMENT_BEGIN';
export const ROADMAP_SET_COMPETENCY_ASSESSMENT_SUCCESS = 'ROADMAP_SET_COMPETENCY_ASSESSMENT_SUCCESS';
export const ROADMAP_SET_COMPETENCY_ASSESSMENT_FAILURE = 'ROADMAP_SET_COMPETENCY_ASSESSMENT_FAILURE';
export const ROADMAP_SET_COMPETENCY_ASSESSMENT_DISMISS_ERROR = 'ROADMAP_SET_COMPETENCY_ASSESSMENT_DISMISS_ERROR';
export const ROADMAP_SET_ACTION_ITEM_DETAILS_BEGIN = 'ROADMAP_SET_ACTION_ITEM_DETAILS_BEGIN';
export const ROADMAP_SET_ACTION_ITEM_DETAILS_SUCCESS = 'ROADMAP_SET_ACTION_ITEM_DETAILS_SUCCESS';
export const ROADMAP_SET_ACTION_ITEM_DETAILS_FAILURE = 'ROADMAP_SET_ACTION_ITEM_DETAILS_FAILURE';
export const ROADMAP_SET_ACTION_ITEM_DETAILS_DISMISS_ERROR = 'ROADMAP_SET_ACTION_ITEM_DETAILS_DISMISS_ERROR';
export const ROADMAP_FETCH_ROADMAP_BEGIN = 'ROADMAP_FETCH_ROADMAP_BEGIN';
export const ROADMAP_FETCH_ROADMAP_SUCCESS = 'ROADMAP_FETCH_ROADMAP_SUCCESS';
export const ROADMAP_FETCH_ROADMAP_FAILURE = 'ROADMAP_FETCH_ROADMAP_FAILURE';
export const ROADMAP_FETCH_ROADMAP_DISMISS_ERROR = 'ROADMAP_FETCH_ROADMAP_DISMISS_ERROR';
export const ROADMAP_ADD_COMPETENCY_COMMENT_BEGIN = 'ROADMAP_ADD_COMPETENCY_COMMENT_BEGIN';
export const ROADMAP_ADD_COMPETENCY_COMMENT_SUCCESS = 'ROADMAP_ADD_COMPETENCY_COMMENT_SUCCESS';
export const ROADMAP_ADD_COMPETENCY_COMMENT_FAILURE = 'ROADMAP_ADD_COMPETENCY_COMMENT_FAILURE';
export const ROADMAP_ADD_COMPETENCY_COMMENT_DISMISS_ERROR = 'ROADMAP_ADD_COMPETENCY_COMMENT_DISMISS_ERROR';
export const ROADMAP_DELETE_COMPETENCY_COMMENT_BEGIN = 'ROADMAP_DELETE_COMPETENCY_COMMENT_BEGIN';
export const ROADMAP_DELETE_COMPETENCY_COMMENT_SUCCESS = 'ROADMAP_DELETE_COMPETENCY_COMMENT_SUCCESS';
export const ROADMAP_DELETE_COMPETENCY_COMMENT_FAILURE = 'ROADMAP_DELETE_COMPETENCY_COMMENT_FAILURE';
export const ROADMAP_DELETE_COMPETENCY_COMMENT_DISMISS_ERROR = 'ROADMAP_DELETE_COMPETENCY_COMMENT_DISMISS_ERROR';
export const ROADMAP_SET_SELECTED_COMPETENCY = 'ROADMAP_SET_SELECTED_COMPETENCY';
export const ROADMAP_FETCH_RECENT_COMPETENCY_BEGIN = 'ROADMAP_FETCH_RECENT_COMPETENCY_BEGIN';
export const ROADMAP_FETCH_RECENT_COMPETENCY_SUCCESS = 'ROADMAP_FETCH_RECENT_COMPETENCY_SUCCESS';
export const ROADMAP_FETCH_RECENT_COMPETENCY_FAILURE = 'ROADMAP_FETCH_RECENT_COMPETENCY_FAILURE';
export const ROADMAP_FETCH_RECENT_COMPETENCY_DISMISS_ERROR = 'ROADMAP_FETCH_RECENT_COMPETENCY_DISMISS_ERROR';
export const ROADMAP_SUBMIT_RECENT_COMPETENCY_BEGIN = 'ROADMAP_SUBMIT_RECENT_COMPETENCY_BEGIN';
export const ROADMAP_SUBMIT_RECENT_COMPETENCY_SUCCESS = 'ROADMAP_SUBMIT_RECENT_COMPETENCY_SUCCESS';
export const ROADMAP_SUBMIT_RECENT_COMPETENCY_FAILURE = 'ROADMAP_SUBMIT_RECENT_COMPETENCY_FAILURE';
export const ROADMAP_SUBMIT_RECENT_COMPETENCY_DISMISS_ERROR = 'ROADMAP_SUBMIT_RECENT_COMPETENCY_DISMISS_ERROR';
export const ROADMAP_ADD_ACTION_ITEM_BEGIN = 'ROADMAP_ADD_ACTION_ITEM_BEGIN';
export const ROADMAP_ADD_ACTION_ITEM_SUCCESS = 'ROADMAP_ADD_ACTION_ITEM_SUCCESS';
export const ROADMAP_ADD_ACTION_ITEM_FAILURE = 'ROADMAP_ADD_ACTION_ITEM_FAILURE';
export const ROADMAP_ADD_ACTION_ITEM_DISMISS_ERROR = 'ROADMAP_ADD_ACTION_ITEM_DISMISS_ERROR';
export const ROADMAP_DELETE_ACTION_ITEM_BEGIN = 'ROADMAP_DELETE_ACTION_ITEM_BEGIN';
export const ROADMAP_DELETE_ACTION_ITEM_SUCCESS = 'ROADMAP_DELETE_ACTION_ITEM_SUCCESS';
export const ROADMAP_DELETE_ACTION_ITEM_FAILURE = 'ROADMAP_DELETE_ACTION_ITEM_FAILURE';
export const ROADMAP_DELETE_ACTION_ITEM_DISMISS_ERROR = 'ROADMAP_DELETE_ACTION_ITEM_DISMISS_ERROR';
export const ROADMAP_ADD_ACTION_ITEM_ATTACHMENT_BEGIN = 'ROADMAP_ADD_ACTION_ITEM_ATTACHMENT_BEGIN';
export const ROADMAP_ADD_ACTION_ITEM_ATTACHMENT_SUCCESS = 'ROADMAP_ADD_ACTION_ITEM_ATTACHMENT_SUCCESS';
export const ROADMAP_ADD_ACTION_ITEM_ATTACHMENT_FAILURE = 'ROADMAP_ADD_ACTION_ITEM_ATTACHMENT_FAILURE';
export const ROADMAP_ADD_ACTION_ITEM_ATTACHMENT_DISMISS_ERROR = 'ROADMAP_ADD_ACTION_ITEM_ATTACHMENT_DISMISS_ERROR';
export const ROADMAP_DELETE_ACTION_ITEM_ATTACHMENT_BEGIN = 'ROADMAP_DELETE_ACTION_ITEM_ATTACHMENT_BEGIN';
export const ROADMAP_DELETE_ACTION_ITEM_ATTACHMENT_SUCCESS = 'ROADMAP_DELETE_ACTION_ITEM_ATTACHMENT_SUCCESS';
export const ROADMAP_DELETE_ACTION_ITEM_ATTACHMENT_FAILURE = 'ROADMAP_DELETE_ACTION_ITEM_ATTACHMENT_FAILURE';
export const ROADMAP_DELETE_ACTION_ITEM_ATTACHMENT_DISMISS_ERROR = 'ROADMAP_DELETE_ACTION_ITEM_ATTACHMENT_DISMISS_ERROR';
export const ROADMAP_ADD_COMPETENCY_ATTACHMENT_BEGIN = 'ROADMAP_ADD_COMPETENCY_ATTACHMENT_BEGIN';
export const ROADMAP_ADD_COMPETENCY_ATTACHMENT_SUCCESS = 'ROADMAP_ADD_COMPETENCY_ATTACHMENT_SUCCESS';
export const ROADMAP_ADD_COMPETENCY_ATTACHMENT_FAILURE = 'ROADMAP_ADD_COMPETENCY_ATTACHMENT_FAILURE';
export const ROADMAP_ADD_COMPETENCY_ATTACHMENT_DISMISS_ERROR = 'ROADMAP_ADD_COMPETENCY_ATTACHMENT_DISMISS_ERROR';
export const ROADMAP_DELETE_COMPETENCY_ATTACHMENT_BEGIN = 'ROADMAP_DELETE_COMPETENCY_ATTACHMENT_BEGIN';
export const ROADMAP_DELETE_COMPETENCY_ATTACHMENT_SUCCESS = 'ROADMAP_DELETE_COMPETENCY_ATTACHMENT_SUCCESS';
export const ROADMAP_DELETE_COMPETENCY_ATTACHMENT_FAILURE = 'ROADMAP_DELETE_COMPETENCY_ATTACHMENT_FAILURE';
export const ROADMAP_DELETE_COMPETENCY_ATTACHMENT_DISMISS_ERROR = 'ROADMAP_DELETE_COMPETENCY_ATTACHMENT_DISMISS_ERROR';
export const ROADMAP_FETCH_ROADMAP_COACHES_BEGIN = 'ROADMAP_FETCH_ROADMAP_COACHES_BEGIN';
export const ROADMAP_FETCH_ROADMAP_COACHES_SUCCESS = 'ROADMAP_FETCH_ROADMAP_COACHES_SUCCESS';
export const ROADMAP_FETCH_ROADMAP_COACHES_FAILURE = 'ROADMAP_FETCH_ROADMAP_COACHES_FAILURE';
export const ROADMAP_FETCH_ROADMAP_COACHES_DISMISS_ERROR = 'ROADMAP_FETCH_ROADMAP_COACHES_DISMISS_ERROR';
export const ROADMAP_BULK_ASSIGN_ROADMAP_COACHES_BEGIN = 'ROADMAP_BULK_ASSIGN_ROADMAP_COACHES_BEGIN';
export const ROADMAP_BULK_ASSIGN_ROADMAP_COACHES_SUCCESS = 'ROADMAP_BULK_ASSIGN_ROADMAP_COACHES_SUCCESS';
export const ROADMAP_BULK_ASSIGN_ROADMAP_COACHES_FAILURE = 'ROADMAP_BULK_ASSIGN_ROADMAP_COACHES_FAILURE';
export const ROADMAP_BULK_ASSIGN_ROADMAP_COACHES_DISMISS_ERROR = 'ROADMAP_BULK_ASSIGN_ROADMAP_COACHES_DISMISS_ERROR';
export const ROADMAP_ADD_NOTE_BEGIN = 'ROADMAP_ADD_NOTE_BEGIN';
export const ROADMAP_ADD_NOTE_SUCCESS = 'ROADMAP_ADD_NOTE_SUCCESS';
export const ROADMAP_ADD_NOTE_FAILURE = 'ROADMAP_ADD_NOTE_FAILURE';
export const ROADMAP_ADD_NOTE_DISMISS_ERROR = 'ROADMAP_ADD_NOTE_DISMISS_ERROR';
export const ROADMAP_FETCH_COMPETENCY_NOTES_BEGIN = 'ROADMAP_FETCH_COMPETENCY_NOTES_BEGIN';
export const ROADMAP_FETCH_COMPETENCY_NOTES_SUCCESS = 'ROADMAP_FETCH_COMPETENCY_NOTES_SUCCESS';
export const ROADMAP_FETCH_COMPETENCY_NOTES_FAILURE = 'ROADMAP_FETCH_COMPETENCY_NOTES_FAILURE';
export const ROADMAP_FETCH_COMPETENCY_NOTES_DISMISS_ERROR = 'ROADMAP_FETCH_COMPETENCY_NOTES_DISMISS_ERROR';
export const ROADMAP_SET_ACTION_ITEM_ASSESSMENT_BEGIN = 'ROADMAP_SET_ACTION_ITEM_ASSESSMENT_BEGIN';
export const ROADMAP_SET_ACTION_ITEM_ASSESSMENT_SUCCESS = 'ROADMAP_SET_ACTION_ITEM_ASSESSMENT_SUCCESS';
export const ROADMAP_SET_ACTION_ITEM_ASSESSMENT_FAILURE = 'ROADMAP_SET_ACTION_ITEM_ASSESSMENT_FAILURE';
export const ROADMAP_SET_ACTION_ITEM_ASSESSMENT_DISMISS_ERROR = 'ROADMAP_SET_ACTION_ITEM_ASSESSMENT_DISMISS_ERROR';
export const ROADMAP_APPROVE_COMPETENCY_ASSESSMENT_BEGIN = 'ROADMAP_APPROVE_COMPETENCY_ASSESSMENT_BEGIN';
export const ROADMAP_APPROVE_COMPETENCY_ASSESSMENT_SUCCESS = 'ROADMAP_APPROVE_COMPETENCY_ASSESSMENT_SUCCESS';
export const ROADMAP_APPROVE_COMPETENCY_ASSESSMENT_FAILURE = 'ROADMAP_APPROVE_COMPETENCY_ASSESSMENT_FAILURE';
export const ROADMAP_APPROVE_COMPETENCY_ASSESSMENT_DISMISS_ERROR = 'ROADMAP_APPROVE_COMPETENCY_ASSESSMENT_DISMISS_ERROR';
export const ROADMAP_UPDATE_COMPETENCY_ATTACHMENT_BEGIN = 'ROADMAP_UPDATE_COMPETENCY_ATTACHMENT_BEGIN';
export const ROADMAP_UPDATE_COMPETENCY_ATTACHMENT_SUCCESS = 'ROADMAP_UPDATE_COMPETENCY_ATTACHMENT_SUCCESS';
export const ROADMAP_UPDATE_COMPETENCY_ATTACHMENT_FAILURE = 'ROADMAP_UPDATE_COMPETENCY_ATTACHMENT_FAILURE';
export const ROADMAP_UPDATE_COMPETENCY_ATTACHMENT_DISMISS_ERROR = 'ROADMAP_UPDATE_COMPETENCY_ATTACHMENT_DISMISS_ERROR';
export const ROADMAP_FETCH_QUESTION_ANSWERS_BEGIN = 'ROADMAP_FETCH_QUESTION_ANSWERS_BEGIN';
export const ROADMAP_FETCH_QUESTION_ANSWERS_SUCCESS = 'ROADMAP_FETCH_QUESTION_ANSWERS_SUCCESS';
export const ROADMAP_FETCH_QUESTION_ANSWERS_FAILURE = 'ROADMAP_FETCH_QUESTION_ANSWERS_FAILURE';
export const ROADMAP_FETCH_QUESTION_ANSWERS_DISMISS_ERROR = 'ROADMAP_FETCH_QUESTION_ANSWERS_DISMISS_ERROR';
export const ROADMAP_ADD_QUESTION_ANSWER_BEGIN = 'ROADMAP_ADD_QUESTION_ANSWER_BEGIN';
export const ROADMAP_ADD_QUESTION_ANSWER_SUCCESS = 'ROADMAP_ADD_QUESTION_ANSWER_SUCCESS';
export const ROADMAP_ADD_QUESTION_ANSWER_FAILURE = 'ROADMAP_ADD_QUESTION_ANSWER_FAILURE';
export const ROADMAP_ADD_QUESTION_ANSWER_DISMISS_ERROR = 'ROADMAP_ADD_QUESTION_ANSWER_DISMISS_ERROR';
export const ROADMAP_UPDATE_QUESTION_ANSWER_BEGIN = 'ROADMAP_UPDATE_QUESTION_ANSWER_BEGIN';
export const ROADMAP_UPDATE_QUESTION_ANSWER_SUCCESS = 'ROADMAP_UPDATE_QUESTION_ANSWER_SUCCESS';
export const ROADMAP_UPDATE_QUESTION_ANSWER_FAILURE = 'ROADMAP_UPDATE_QUESTION_ANSWER_FAILURE';
export const ROADMAP_UPDATE_QUESTION_ANSWER_DISMISS_ERROR = 'ROADMAP_UPDATE_QUESTION_ANSWER_DISMISS_ERROR';
export const ROADMAP_UPDATE_NOTE_BEGIN = 'ROADMAP_UPDATE_NOTE_BEGIN';
export const ROADMAP_UPDATE_NOTE_SUCCESS = 'ROADMAP_UPDATE_NOTE_SUCCESS';
export const ROADMAP_UPDATE_NOTE_FAILURE = 'ROADMAP_UPDATE_NOTE_FAILURE';
export const ROADMAP_UPDATE_NOTE_DISMISS_ERROR = 'ROADMAP_UPDATE_NOTE_DISMISS_ERROR';
export const ROADMAP_DELETE_NOTE_BEGIN = 'ROADMAP_DELETE_NOTE_BEGIN';
export const ROADMAP_DELETE_NOTE_SUCCESS = 'ROADMAP_DELETE_NOTE_SUCCESS';
export const ROADMAP_DELETE_NOTE_FAILURE = 'ROADMAP_DELETE_NOTE_FAILURE';
export const ROADMAP_DELETE_NOTE_DISMISS_ERROR = 'ROADMAP_DELETE_NOTE_DISMISS_ERROR';
export const ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_BEGIN = 'ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_BEGIN';
export const ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_SUCCESS = 'ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_SUCCESS';
export const ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_FAILURE = 'ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_FAILURE';
export const ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_DISMISS_ERROR = 'ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_DISMISS_ERROR';
