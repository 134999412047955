import React, { useState, useCallback, forwardRef, useEffect } from 'react';
import { Header, ActionMenu } from '../common';
import clsx from 'clsx';
import useEffectiveBackLink from '../common/useEffectiveBackLinkHook';
import { Link, useHistory, useParams, Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import Select from 'react-select';
import Switch from 'react-switch';
import { useUpdateCoachCall, useFetchAssignedUsers, useFetchCoachUpcomingCalls } from './redux/hooks';
import { useDeleteCoachCall } from './redux/hooks';

const coachEditCallsSchema = yup.object().shape({
  call_name: yup.string(),
  timestamp: yup.string(),
  meeting_link: yup.string().matches(/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/).required(),
  selectedParticipants: yup.array(),
  recours_weekly: yup.boolean()
});
export default function CoachEditCallsPage() {
  const { callId } = useParams();
  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers();

  const history = useHistory();

  const {  register, handleSubmit, control, errors, setError} = useForm({
    resolver: yupResolver(coachEditCallsSchema),
  });

  const defaultBackLink = `/dashboard/coach`;

  const effectiveBackLink = useEffectiveBackLink(defaultBackLink);

  const { updateCoachCall, updateCoachCallPending } = useUpdateCoachCall();

  const handleError = useCallback(
    err =>
      Object.keys(err).forEach(key => {
        const errors = err[key];
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' });
        }
      }),
    [setError],
  );

  const handleSaveClick = useCallback(({ call_id, call_name, timestamp, meeting_link, selectedParticipants, recours_weekly }) => {
    timestamp = new Date(timestamp).getTime();
    const participants = selectedParticipants.map((e) => e.id)

    if (!meeting_link?.match(/^https?:\/\//)) meeting_link = "http://" + meeting_link;
    updateCoachCall({ call_id, call_name, timestamp, meeting_link, participants, recours_weekly })
    .then(() => history.push(effectiveBackLink))
    .catch(e => handleError(e.response.data))

  }, [updateCoachCall, history, handleError, effectiveBackLink]);

  const renderBackLink = useCallback(
    effectiveBackLink => (
      <Link to={effectiveBackLink}>
        <Button className="btn-secondary bold" variant="light">
          Cancel
        </Button>
      </Link>
    ),
    [],
  );

  const { deleteCoachCall } = useDeleteCoachCall();
  const deleteCallHandler = useCallback(() => {
    deleteCoachCall({ callId })
    .then(() => history.push(effectiveBackLink))
    .catch(e => console.log(e.response.data))
  },[callId, effectiveBackLink, deleteCoachCall, history]);

  const [detailMenu, setDetailMenu] = useState(null)
  const handleUserDetailClick = useCallback(userId => () => setDetailMenu(userId), [])
  const handleHideDetailMenu = useCallback(() => setDetailMenu(false), [])
  const actionMenuItems = useCallback(() => {
    const items = [
      { label: "Are you sure you want to delete this call?" },
      {
        label: 'Delete Call',
        onClick: deleteCallHandler,
        className: 'text-danger'
      }
    ]
    return items
  }, [deleteCallHandler])

  const mobileMultiSelectStyles = {
    control: (styles) => {
      const custom = {
        ...styles,
        borderRadius: '10px',
        cursor: 'text',
        borderColor: '#eeeeee',
        minHeight: '40px',
        '&:hover': {
          borderColor: '#eeeeee',
        },
      }
      return custom
    }
  }

  const renderSaveButton = useCallback(
    () => (
      <Button
        className="btn-save"
        variant="link"
        disabled={updateCoachCallPending}
        onClick={handleSubmit((handleSaveClick))}
      >
        Save
      </Button>
    ),
    [handleSaveClick, handleSubmit, updateCoachCallPending],
  );

  const DatePickerButton = forwardRef(({ value, onClick, className }, ref) => (
    <Button
      ref={ref}
      onClick={onClick}
      variant="white"
      className={clsx("btn-due-date btn-center", className)}
    >
      <FontAwesomeIcon icon={faCalendar} size='sm' className='mr-2' />
      {value || 'Date'}
    </Button>
  ));

  const multiselectList = {
    assignedUsers: assignedUsers ? assignedUsers.results.map(item => ({ id: item.id, full_name: `${item.first_name} ${item.last_name}` })) : null,
  }

  useEffect(() => {
    fetchAssignedUsers()
  }, [fetchAssignedUsers])

  const { coachCalls } = useFetchCoachUpcomingCalls();

  const getCallById = (object, value) => {
    return Object.keys(object).find(key => object[key]['id'] === value);
  }

  const getUserById = (object, value) => {
    return Object.keys(object).find(key => object[key]['id'] === value);
  }

  const defaultCallValues = coachCalls.results[getCallById(coachCalls.results, parseInt(callId, 10))]

  const defaultParticipants = defaultCallValues.participants.map((e) => {
    const getName = getUserById(assignedUsers.results, e)
    if (getName) {
      return {id: e, full_name: `${assignedUsers.results[getName].first_name} ${assignedUsers.results[getName].last_name}`}
    }
    return {id: e, full_name: 'Unknown User'}
  })

  const defaultDateValue = new Date(defaultCallValues.timestamp);

  if (!coachCalls) {
    return <Redirect to='/dashboard' />
  }

  return (
    <div className="dashboard-coach-add-calls-page mrm-pb-3">

      <Header
        icon="back"
        title="Edit Call"
        renderThirdColumn={renderSaveButton}
        thirdColumnClass="text-right"
        colSizes={['auto', undefined, 'auto']}
        border
        renderBackLink={renderBackLink}
        defaultBackLink={defaultBackLink}
      >
      </Header>

      <Form className='mrm-p-1 mt-n5'>

        <Form.Group controlId='call_name'>
          <Form.Label>
            Call Name
          </Form.Label>
          <Form.Control
            name='call_name'
            type="text"
            defaultValue={defaultCallValues.call_name}
            isInvalid={errors.call_name}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId="timestamp" className="mb-0 d-flex flex-column mb-4">
          <Form.Label>
            Date/Time
          </Form.Label>
          <Controller
            name="timestamp"
            control={control}
            isInvalid={errors.date}
            defaultValue={defaultDateValue}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                showTimeSelect
                minDate={new Date()}
                timeIntervals={15}
                dateFormat="MMMM d @ h:mm aa"
                customInput={<DatePickerButton className={clsx({'is-invalid': errors.timestamp})}/>}
                onChange={onChange}
                className="ml-0"
              />
            )}
          />
        </Form.Group>

        {/* <Form.Group controlId='time_zone' className="d-none">
          <Form.Control
            name='time_zone'
            type="text"
            defaultValue={dayjs.tz.guess()}
            isInvalid={errors.time_zone}
            ref={register}
          />
        </Form.Group> */}

        <Form.Group controlId='selectedParticipants'>
          <Form.Label>
            Participants
          </Form.Label>
          <Controller 
          name="selectedParticipants"
          control={control}
          defaultValue={defaultParticipants}
          isInvalid={errors.participants}
          render={({ onChange, value }) => 
            <Select
              isMulti
              defaultValue={defaultParticipants}
              value={value}
              options={multiselectList.assignedUsers}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              onChange={onChange}
              styles={mobileMultiSelectStyles}
            />
          }
          />
        </Form.Group>

        <Form.Group controlId='meeting_link'>
          <Form.Label>
            Meeting Link
          </Form.Label>
          <Form.Control
            name='meeting_link'
            type="text"
            defaultValue={defaultCallValues.meeting_link}
            isInvalid={errors.meeting_link}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId="recours_weekly" className="mb-0 d-flex flex-column mb-4">
          <Form.Label>
            Recurs Weekly
          </Form.Label>
          <Controller 
          name="recours_weekly"
          control={control}
          defaultValue={defaultCallValues.recours_weekly}
          isInvalid={errors.recours_weekly}
          render={({ onChange, value }) => 
            <Switch
              onChange={onChange}
              checked={!!value}
              onColor='#2f80ed'
            />
          }
          />
        </Form.Group>

        <Form.Group controlId='call_id' className='d-none'>
          <Form.Control
            name='call_id'
            type="number"
            defaultValue={callId}
            ref={register}
          />
        </Form.Group>

      </Form>

      <Button variant="white" className="btn mx-auto d-block" onClick={handleUserDetailClick(callId)}><FontAwesomeIcon icon={faTrashAlt} size="xs" className="align-middle" />&nbsp;&nbsp; Delete Call</Button>

      {detailMenu && (
        <ActionMenu
          show={!!detailMenu}
          onHide={handleHideDetailMenu}
          items={actionMenuItems()}
        />
      )}

    </div>
  );
}

export function EditCallModal({ callId, show, onHide }) {

  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers();
  const { coachCalls } = useFetchCoachUpcomingCalls();

  useEffect(() => {
    fetchAssignedUsers()
  }, [fetchAssignedUsers])

  const getCallById = (object, value) => {
    return Object.keys(object).find(key => object[key]['id'] === value);
  }

  const getUserById = (object, value) => {
    return Object.keys(object).find(key => object[key]['id'] === value);
  }

  const defaultCallValues = coachCalls.results[getCallById(coachCalls.results, parseInt(callId, 10))]

  const defaultParticipants = defaultCallValues.participants.map((e) => {
    const getName = !!assignedUsers && getUserById(assignedUsers.results, e)
    if (getName) {
      return {id: e, full_name: `${assignedUsers.results[getName].first_name} ${assignedUsers.results[getName].last_name}`}
    }
    return {id: e, full_name: 'Unknown User'}
  })

  const multiselectList = {
    assignedUsers: assignedUsers ? assignedUsers.results.map(item => ({ id: item.id, full_name: `${item.first_name} ${item.last_name}` })) : null,
  }

  const defaultDateValue = new Date(defaultCallValues.timestamp);

  const {  register, handleSubmit, control, errors, setError} = useForm({
    resolver: yupResolver(coachEditCallsSchema),
  });

  const DatePickerButton = forwardRef(({ value, onClick, className }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      className={clsx('form-control hover', {'text-muted': !value})}
    >
      <FontAwesomeIcon icon={faCalendar} size='sm' className='mr-2' />
      {value || 'Select Date & Time'}
    </div>
  ));

  const { updateCoachCall, updateCoachCallPending } = useUpdateCoachCall();

  const handleError = useCallback(
    err =>
      Object.keys(err).forEach(key => {
        const errors = err[key];
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' });
        }
      }),
    [setError],
  );

  const desktopMultiSelectStyles = {
    control: (styles) => {
      const custom = {
        ...styles,
        borderRadius: '10px',
        cursor: 'text',
        borderColor: '#cccccc',
        minHeight: '40px',
        '&:hover': {
          borderColor: '#cccccc',
        },
      }
      return custom
    }
  }

  const handleSaveClick = useCallback(({ call_id, call_name, timestamp, time_zone, meeting_link, selectedParticipants, recours_weekly}) => {
    timestamp = new Date(timestamp).valueOf();
    const participants = selectedParticipants.map((e) => e.id)

    if (!meeting_link?.match(/^https?:\/\//)) meeting_link = "http://" + meeting_link;
    updateCoachCall({ call_id, call_name, timestamp, meeting_link, participants, recours_weekly })
    .then(() => onHide())
    .catch(e => handleError(e.response.data))

  }, [updateCoachCall, handleError, onHide]);

  const { deleteCoachCall } = useDeleteCoachCall();

  const deleteCallHandler = useCallback(() => {
    deleteCoachCall({ callId })
    .then(() => onHide())
    .catch(e => console.log(e.response.data))
  },[callId, deleteCoachCall, onHide]);

  const [detailMenu, setDetailMenu] = useState(null)
  const handleUserDetailClick = useCallback(userId => () => setDetailMenu(userId), [])
  const handleHideDetailMenu = useCallback(() => setDetailMenu(false), [])
  const actionMenuItems = useCallback(() => {
    const items = [
      { label: "Are you sure you want to delete this call?" },
      {
        label: 'Delete Call',
        onClick: deleteCallHandler,
        className: 'text-danger'
      }
    ]
    return items
  }, [deleteCallHandler])


  return (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header>
      {!updateCoachCallPending && <>
        <Button variant="secondary" onClick={() => onHide()} className="font-weight-bold">
          Cancel
        </Button>
        <h2 className="mrm-mt-1 text-center">Edit: {defaultCallValues.call_name}</h2>
        <Button onClick={handleSubmit(handleSaveClick)}>
          Save 
        </Button>
      </>}
    </Modal.Header>
    <Modal.Body>
      <Form className='mrm-p-1'>

        <Form.Group controlId='call_name'>
          <Form.Label>
            Call Name
          </Form.Label>
          <Form.Control
            name='call_name'
            type="text"
            defaultValue={defaultCallValues.call_name}
            isInvalid={errors.call_name}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId="timestamp" className="mb-0 d-flex flex-column mb-4">
          <Form.Label>
            Date/Time
          </Form.Label>
          <Controller
            name="timestamp"
            control={control}
            isInvalid={errors.date}
            defaultValue={defaultDateValue}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                showTimeSelect
                minDate={new Date()}
                timeIntervals={15}
                dateFormat="MMMM d @ h:mm aa"
                customInput={<DatePickerButton className={clsx({'is-invalid': errors.timestamp})}/>}
                onChange={onChange}
                className="text-primary ml-0"
              />
            )}
          />
        </Form.Group>

        {/* <Form.Group controlId='time_zone' className="d-none">
          <Form.Control
            name='time_zone'
            type="text"
            defaultValue={dayjs.tz.guess()}
            isInvalid={errors.time_zone}
            ref={register}
          />
        </Form.Group> */}

        <Form.Group controlId='selectedParticipants'>
          <Form.Label>
            Participants
          </Form.Label>
          <Controller 
          name="selectedParticipants"
          control={control}
          defaultValue={defaultParticipants}
          isInvalid={errors.participants}
          render={({ onChange, value }) => 
            <Select
              isMulti
              defaultValue={defaultParticipants}
              value={value}
              options={multiselectList.assignedUsers}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              onChange={onChange}
              styles={desktopMultiSelectStyles}
            />
          }
          />
        </Form.Group>

        <Form.Group controlId='meeting_link'>
          <Form.Label>
            Meeting Link
          </Form.Label>
          <Form.Control
            name='meeting_link'
            type="text"
            defaultValue={defaultCallValues.meeting_link}
            isInvalid={errors.meeting_link}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId="recours_weekly" className="mb-0 d-flex flex-column mb-2">
          <Form.Label>
            Recurs Weekly
          </Form.Label>
          <Controller 
          name="recours_weekly"
          control={control}
          defaultValue={defaultCallValues.recours_weekly}
          isInvalid={errors.recours_weekly}
          render={({ onChange, value }) => 
            <Switch
              onChange={onChange}
              checked={!!value}
              onColor='#2f80ed'
            />
          }
          />
        </Form.Group>

        <Form.Group controlId='call_id' className='d-none'>
          <Form.Control
            name='call_id'
            type="number"
            defaultValue={callId}
            ref={register}
          />
        </Form.Group> 

        </Form>

    </Modal.Body>

    <div className="border-thin" />

    <Button variant="gray" className="btn mx-auto d-block mrm-m-1_5" onClick={handleUserDetailClick(callId)}><FontAwesomeIcon icon={faTrashAlt} size="xs" className="align-middle" />&nbsp;&nbsp; Delete Call</Button>

    {detailMenu && (
      <ActionMenu
        show={!!detailMenu}
        onHide={handleHideDetailMenu}
        items={actionMenuItems()}
      />
    )}
  </Modal>
  )
}