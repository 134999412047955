import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { Loader } from '../common';
import dayjs from 'dayjs';
import { useFetchUserUpcomingCalls } from './redux/hooks';
import clsx from 'clsx';
// import { Col, Container } from 'react-bootstrap';


function AdminPageCallHeader(
  {
    userCalls,
    handleViewAll
  }
) {
  const [isOpen, setIsOpen] = useState(false);

  const handleViewAllChange = useCallback(event => {
    if (isOpen) {
      handleViewAll(4)
      setIsOpen(!isOpen)
    } else {
      handleViewAll(undefined)
      setIsOpen(!isOpen)
    }
  }, [handleViewAll, isOpen])

  const viewAllLabel = userCalls && userCalls.count <= 4 ? "" : "View all"
  const colapseLabel = userCalls && userCalls.count <= 4 ? "" : "Collapse"

  return (
  <div className="d-flex flex-column">
    <div className="d-flex flex-row justify-content-between align-items-center">
      { userCalls && userCalls.count > 0 &&
        <h2 className="dashboard-title theme-text-normal mrm-mb-1 mrm-mt-1">
          Upcoming Calls&nbsp;
          {userCalls && <strong>({userCalls.count})</strong>}
        </h2>
      }
      <div className="d-lg-none">
        <Link
          className="text-muted" href="/dashboard/upcoming-calls"
          to="/dashboard/upcoming-calls"
        >
          {viewAllLabel}
        </Link>
      </div>
      <div className="d-none d-lg-block">
        <Link
          className="text-muted" onClick={() => {handleViewAllChange()}}
        >
          {isOpen ? colapseLabel: viewAllLabel}
        </Link>
      </div>
    </div>
  </div>);
}

const renderCallContent = (userCalls, fetchCoachCallsPending, fetchCoachCalls, showAll ) => {
  if (!userCalls) {
    return <Loader />
  } else if (userCalls.count === 0) {
    return (
      // <Container>
      //   <Row>
      //     <Col>
      //       <p className="no-data text-left">No upcoming calls.</p>
      //     </Col>
      //   </Row>
      // </Container>
      ''
    )
  } else {
    const lastCoachCalls = userCalls.results.length <= 4 ? userCalls.results.sort(function(x, y){return x.timestamp - y.timestamp;}) : userCalls.results.slice(userCalls.results.length - showAll).reverse()
    return (
      <>
        {lastCoachCalls.map(u => (
          <UserCallCard
            key={u.id}
            user={u}
            callName={u.call_name}
            date={u.timestamp}
            meetingLink={u.meeting_link}
          />
        ))}
        {fetchCoachCallsPending && (
          <Loader />
        )}
      </>
    )
  }
}

const UserCallCard = (
  {
    user,
    callName,
    date,
    startTime,
    participants,
    meetingLink,
    weekly
  }
) => {
  var calendar = require('dayjs/plugin/calendar')
  dayjs.extend(calendar)
  return (
      <div
        className={clsx('mrm-mb-1 upcomingcalls-card col-12 col-lg-6')}
      >
        <Card className="position-relative mrm-py-0_75 mrm-px-1">
          <Card.Title className="h6 text-decoration-none font-weight-bold text-left">
            <a href={meetingLink} target="_blank" rel="noopener noreferrer" className="text-reset">
              <FontAwesomeIcon icon={faPhone} size='xs' className='mr-2' />
              {callName}
            </a>
          </Card.Title>
          <div className="d-flex">
            <span className="item-count gray-count">
              <FontAwesomeIcon icon={faCalendarWeek} size='xs' />
              &nbsp;
              {dayjs(date).calendar(null, {
                sameDay: '[Today] @ h:mm A', // The same day ( Today @ 2:30 AM )
                nextDay: '[Tomorrow] @ h:mm A', // The next day ( Tomorrow @ 2:30 AM )
                nextWeek: 'dddd @ h:mm A', // The next week ( Sunday @ 2:30 AM )
                sameElse: 'MMMM D @ h:mm A' // Everything else ( July 15 @ 2:00pm )
              })}
            </span>
          </div>
        </Card>
      </div>
  )
}

export default function UserUpcomingCalls() {
const { userCalls,   fetchUserUpcomingCallsPending, fetchUserUpcomingCalls } = useFetchUserUpcomingCalls();
const [showAll, setshowAll] = useState(4)
  useEffect(() => {
    fetchUserUpcomingCalls();
  }, [fetchUserUpcomingCalls])
  return (
    <div className="dashboard-coach-add-calls">
      <AdminPageCallHeader userCalls={userCalls} handleViewAll={setshowAll} />
        <Row>
          {renderCallContent(userCalls, fetchUserUpcomingCallsPending, fetchUserUpcomingCalls, showAll, setshowAll)}
        </Row>
    </div>
  );
};

UserUpcomingCalls.propTypes = {};
UserUpcomingCalls.defaultProps = {};
