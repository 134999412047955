import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_BEGIN,
  ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_SUCCESS,
  ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_FAILURE,
  ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_DISMISS_ERROR,
} from './constants';

import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function fetchRoadmapStagesInfinite(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_BEGIN,
    });

    const { roadmapId, studentId, ordering, pageSize, page } = args;
    const requestConfig = createAxiosConfigWithAuth(getState());

    if (studentId) requestConfig.params = { forStudent: studentId };

    if (!!ordering) requestConfig.params = { ...requestConfig.params, ordering };
    if (!!pageSize) requestConfig.params = { ...requestConfig.params, pageSize };
    if (!!page) requestConfig.params = { ...requestConfig.params, page };

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${config.apiRootUrl}/roadmaps/${roadmapId}/stages/`,
        requestConfig,
      );
      doRequest.then(
        res => {
          dispatch({
            type: ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_SUCCESS,
            data: res.data.results,
          });
          resolve(res.data.results);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          dispatch({
            type: ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchRoadmapStagesInfiniteError() {
  return {
    type: ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_DISMISS_ERROR,
  };
}

export function useFetchRoadmapStagesInfinite(params) {
  const dispatch = useDispatch();

  const { stages, fetchRoadmapStagesInfinitePending, fetchRoadmapStagesInfiniteError } = useSelector(
    state => ({
      stages: state.roadmap.stages,
      fetchRoadmapStagesInfinitePending: state.roadmap.fetchRoadmapStagesInfinitePending,
      fetchRoadmapStagesInfiniteError: state.roadmap.fetchRoadmapStagesInfiniteError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchRoadmapStagesInfinite(...args));
    },
    [dispatch],
  );

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchRoadmapStagesInfiniteError());
  }, [dispatch]);

  return {
    stages,
    fetchRoadmapStagesInfinite: boundAction,
    fetchRoadmapStagesInfinitePending,
    fetchRoadmapStagesInfiniteError,
    dismissFetchRoadmapStagesInfiniteError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchRoadmapStagesInfinitePending: true,
        fetchRoadmapStagesInfiniteError: null,
      };

    case ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchRoadmapStagesInfinitePending: false,
        fetchRoadmapStagesInfiniteError: null,
        stages: Object.assign({}, state.stages, ...action.data.map(s => ({ [s.id]: s }))),
      };

    case ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchRoadmapStagesInfinitePending: false,
        fetchRoadmapStagesInfiniteError: action.data.error,
      };

    case ROADMAP_FETCH_ROADMAP_STAGES_INFINITE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchRoadmapStagesInfiniteError: null,
      };

    default:
      return state;
  }
}
