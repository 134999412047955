import React, { useCallback, forwardRef, useEffect } from 'react';
import { Header, Loader } from '../common';
import clsx from 'clsx';
import useEffectiveBackLink from '../common/useEffectiveBackLinkHook';
import { Link, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons';
import Select from 'react-select';
import Switch from 'react-switch';
import { useAddCoachCall } from './redux/hooks';
import { useFetchAssignedUsers } from './redux/hooks';

const coachAddCallsSchema = yup.object().shape({
  call_name: yup.string().required(),
  timestamp: yup.string().required(),
  time_zone: yup.string(),
  meeting_link: yup.string().matches(/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/).required(),
  selectedParticipants: yup.array().required(),
  recours_weekly: yup.boolean().required()
});

export default function CoachAddCallsPage() {
  const history = useHistory();
  const {  register, handleSubmit, control, errors, setError} = useForm({
    resolver: yupResolver(coachAddCallsSchema),
  });
  const defaultBackLink = `/dashboard/coach`;
  const effectiveBackLink = useEffectiveBackLink(defaultBackLink);

  const { addCoachCall, addCoachCallPending } = useAddCoachCall();

  const handleError = useCallback(
    err =>
      Object.keys(err).forEach(key => {
        const errors = err[key];
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' });
        }
      }),
    [setError],
  );

  const handleSaveClick = useCallback(({ call_name, timestamp, time_zone, meeting_link, selectedParticipants, recours_weekly}) => {
    timestamp = new Date(timestamp).valueOf();
    const participants = selectedParticipants.map((e) => e.id)

    if (!meeting_link?.match(/^https?:\/\//)) meeting_link = "http://" + meeting_link;
    addCoachCall({ call_name, timestamp, time_zone, meeting_link, participants, recours_weekly })
    .then(() => history.push(effectiveBackLink))
    .catch(e => handleError(e.response.data))

  }, [addCoachCall, history, handleError, effectiveBackLink]);

  const renderBackLink = useCallback(
    effectiveBackLink => (
      <Link to={effectiveBackLink}>
        <Button className="btn-secondary bold" variant="light">
          Cancel
        </Button>
      </Link>
    ),
    [],
  );
  const mobileMultiSelectStyles = {
    control: (styles, state) => {
      const custom = {
        ...styles,
        borderRadius: '10px',
        cursor: 'text',
        borderColor: '#eeeeee',
        minHeight: '40px',
        '&:hover': {
          borderColor: '#eeeeee',
        },
      }
      return custom
    }
  }
  const renderSaveButton = useCallback(
    () => (
      <Button
        className="btn-save"
        variant="link"
        disabled={addCoachCallPending}
        onClick={handleSubmit(handleSaveClick)}
      >
        Add
      </Button>
    ),
    [handleSaveClick, handleSubmit, addCoachCallPending],
  );
  const DatePickerButton = forwardRef(({ value, onClick, className }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      variant="white"
      className={clsx('form-control hover', {'text-muted': !value})}
    >
      <FontAwesomeIcon icon={faCalendar} size='sm' className='mr-2' />
      {value || 'Select Date & Time'}
    </div>
  ));
  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers();
  const multiselectList = {
    assignedUsers: assignedUsers ? assignedUsers.results.map(item => ({ id: item.id, full_name: `${item.first_name} ${item.last_name}` })) : null,
  }
  useEffect(() => {
    fetchAssignedUsers()
  }, [fetchAssignedUsers])
  return (
    <div className="dashboard-coach-add-calls-page">
      <Header
        icon="back"
        title="Add Call"
        renderThirdColumn={renderSaveButton}
        thirdColumnClass="text-right"
        colSizes={['auto', undefined, 'auto']}
        border
        renderBackLink={renderBackLink}
        defaultBackLink={defaultBackLink}
      >
      </Header>
      <Form className='mrm-p-1 mt-n5'>

        <Form.Group controlId='call_name'>
          <Form.Label>
            Call Name
          </Form.Label>
          <Form.Control
            name='call_name'
            type="text"
            isInvalid={errors.call_name}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId="timestamp" className="mb-0 d-flex flex-column mb-4">
          <Form.Label>
            Date/Time
          </Form.Label>
          <Controller
            name="timestamp"
            control={control}
            isInvalid={errors.timestamp}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                showTimeSelect
                minDate={new Date()}
                timeIntervals={15}
                dateFormat="MMMM d @ h:mm aa"
                customInput={<DatePickerButton className={clsx({'is-invalid': errors.timestamp})}/>}
                onChange={onChange}
                className="text-primary ml-0"
              />
            )}
          />
        </Form.Group>

        <Form.Group controlId="time_zone" className="d-none">
          <Form.Control
            name="time_zone"
            type="text"
            isInvalid={errors.time_zone}
            ref={register}
            value={dayjs.tz.guess()}
          />
        </Form.Group>

        <Form.Group controlId='selectedParticipants'>
          <Form.Label>
            Participants
          </Form.Label>
          <Controller 
          name="selectedParticipants"
          control={control}
          isInvalid={errors.participants}
          render={({ onChange, value }) => 
            <Select
              isMulti
              defaultValue={[]}
              value={value}
              options={multiselectList.assignedUsers}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              onChange={onChange}
              styles={mobileMultiSelectStyles}
            />
          }
          />
        </Form.Group>

        <Form.Group controlId='meeting_link'>
          <Form.Label>
            Meeting Link
          </Form.Label>
          <Form.Control
            name='meeting_link'
            type="text"
            isInvalid={errors.meeting_link}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId="recours_weekly" className="mb-0 d-flex flex-column mb-4">
          <Form.Label>
            Recurs Weekly
          </Form.Label>
          <Controller 
          name="recours_weekly"
          control={control}
          defaultValue={false}
          isInvalid={errors.recours_weekly}
          render={({ onChange, value }) => 
            <Switch
              onChange={onChange}
              checked={!!value}
              onColor='#2f80ed'
            />
          }
          />
        </Form.Group>

      </Form>
    </div>
  );
}


export function AddCallModal({show, onHide}) {
  const {  register, handleSubmit, control, errors, setError} = useForm({
    resolver: yupResolver(coachAddCallsSchema),
  });
  const DatePickerButton = forwardRef(({ value, onClick, className }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      className={clsx('form-control hover', {'text-muted': !value})}
    >
      <FontAwesomeIcon icon={faCalendar} size='sm' className='mr-2' />
      {value || 'Select Date & Time'}
    </div>
  ));
  const { addCoachCall, addCoachCallPending } = useAddCoachCall();
  const handleError = useCallback(
    err =>
      Object.keys(err).forEach(key => {
        const errors = err[key];
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' });
        }
      }),
    [setError],
  );
  const desktopMultiSelectStyles = {
    control: (styles, state) => {
      const custom = {
        ...styles,
        borderRadius: '10px',
        cursor: 'text',
        borderColor: '#cccccc',
        minHeight: '40px',
        '&:hover': {
          borderColor: '#cccccc',
        },
      }
      return custom
    }
  }
  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers();
  const multiselectList = {
    assignedUsers: assignedUsers ? assignedUsers.results.map(item => ({ id: item.id, full_name: `${item.first_name} ${item.last_name}` })) : null,
  }
  useEffect(() => {
    fetchAssignedUsers()
  }, [fetchAssignedUsers])
  // const [saveButtonProps, setSaveButtonProps] = useState({})

  const handleSaveClick = useCallback(({ call_name, timestamp, time_zone, meeting_link, selectedParticipants, recours_weekly}) => {
    timestamp = new Date(timestamp).valueOf();
    const participants = selectedParticipants.map((e) => e.id)

    if (!meeting_link?.match(/^https?:\/\//)) meeting_link = "http://" + meeting_link;
    addCoachCall({ call_name, timestamp, time_zone, meeting_link, participants, recours_weekly })
    .then(() => onHide())
    .catch(e => handleError(e.response.data))

  }, [addCoachCall, handleError, onHide]);

  return (<Modal show={show} onHide={onHide} centered>
    <Modal.Header>
      {addCoachCallPending && <Loader />}
      <Button variant="secondary" onClick={() => onHide()} className="font-weight-bold">
        Cancel
      </Button>
      <h2 className="mrm-mt-1">Add New Call</h2>
      <Button onClick={handleSubmit(handleSaveClick)}>
        Save 
      </Button>
    </Modal.Header>
    <Modal.Body>
      <Form className='mrm-p-1'>
        <Form.Group controlId='call_name'>
          <Form.Label>
            Call Name
          </Form.Label>
          <Form.Control
            name='call_name'
            type="text"
            isInvalid={errors.call_name}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId="timestamp" className="mb-0 d-flex flex-column mb-4">
          <Form.Label>
            Date/Time
          </Form.Label>
          <Controller
            name="timestamp"
            control={control}
            isInvalid={errors.timestamp}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                showTimeSelect
                minDate={new Date()}
                timeIntervals={15}
                dateFormat="MMMM d @ h:mm aa"
                customInput={<DatePickerButton className={clsx({'is-invalid': errors.timestamp})}/>}
                onChange={onChange}
                className="ml-0"
              />
            )}
          />
        </Form.Group>

        <Form.Group controlId="time_zone" className="d-none">
          <Form.Control
            name="time_zone"
            type="text"
            isInvalid={errors.time_zone}
            ref={register}
            value={dayjs.tz.guess()}
          />
        </Form.Group>

        <Form.Group controlId='selectedParticipants'>
          <Form.Label>
            Participants
          </Form.Label>
          <Controller 
          name="selectedParticipants"
          control={control}
          isInvalid={errors.participants}
          render={({ onChange, value }) => 
            <Select
              isMulti
              defaultValue={[]}
              value={value}
              options={multiselectList.assignedUsers}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              onChange={onChange}
              styles={desktopMultiSelectStyles}
            />
          }
          />
        </Form.Group>

        <Form.Group controlId='meeting_link'>
          <Form.Label>
            Meeting Link
          </Form.Label>
          <Form.Control
            name='meeting_link'
            type="text"
            isInvalid={errors.meeting_link}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId="recours_weekly" className="mb-0 d-flex flex-column mb-4">
          <Form.Label>
            Recurs Weekly
          </Form.Label>
          <Controller 
          name="recours_weekly"
          control={control}
          defaultValue={false}
          isInvalid={errors.recours_weekly}
          render={({ onChange, value }) => 
            <Switch
              onChange={onChange}
              checked={!!value}
              onColor='#2f80ed'
            />
          }
          />
        </Form.Group>

      </Form>
    </Modal.Body>
  </Modal>)
}