import React, { useCallback, useEffect, useState } from 'react';
import { Header } from '../common';
// import useEffectiveBackLink from '../common/useEffectiveBackLinkHook';
import { Link, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Switch from 'react-switch';

import { useAddUser , useBulkAssignUserRoadmaps} from './redux/hooks';
import { useFetchUser } from '../user/redux/hooks';

import { Loader } from '../common';

const schema = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string(),
  email: yup.string().email(),
  phone_number: yup.string(),
  groups: yup.array(),//User role
  cohort: yup.array().nullable(),
  roadmaps_info: yup.array().nullable(),
  isApproved: yup.bool(),
})

const customComponentDefaultValues = {
  cohort: [],
  roadmaps_info: [],
  is_approved: true
}

export default function CoachAddUserPage() {


  const history = useHistory()

  const { user } = useFetchUser();

  const { register, handleSubmit, control, errors, setError, getValues, clearErrors } = useForm({
    resolver: yupResolver(schema),
  });

  const { bulkAssignUserRoadmaps } = useBulkAssignUserRoadmaps()

  const [saveButtonProps, setSaveButtonProps] = useState({})

  const { addUser, addUserPending } = useAddUser()  

  // const { roadmaps, fetchRoadmaps } = useFetchRoadmaps()

  // const { fetchCohorts, cohorts } = useFetchCohorts()

  const defaultBackLink = `/dashboard/coach`;

  // const multiSelectStyles = {
  //   control: (styles, state) => {
  //     const custom = {
  //       ...styles,
  //       borderRadius: '10px',
  //       cursor: 'text',
  //       borderColor: '#cccccc',
  //       minHeight: '40px',
  //       '&:hover': {
  //         borderColor: '#cccccc',
  //       },
  //       '&:active': {
  //         borderColor: '#6E9FE0',
  //       },
  //       '&:focus': {
  //         borderColor: '#6E9FE0',
  //       },
  //       "@media only screen and (max-width: 991px)": {
  //         ...styles["@media only screen and (max-width: 991px)"],
  //         borderColor: '#eeeeee',
  //       },
  //     }
  //     if (state.isFocused) {
  //       custom.boxShadow = 'none'
  //     }
  //     return custom
  //   }
  // }

  const renderBackLink = useCallback(
    effectiveBackLink => (
      <Link to={effectiveBackLink}>
        <Button className="btn-secondary bold" variant="secondary">
          Cancel
        </Button>
      </Link>
    ),
    [],
  );

  const handleError = useCallback(
    err => Object.keys(err).forEach(key => {
      const errors = err[key]
      if (errors.length) {
        setError(key, { message: errors[0], type: 'remote' })
      }
    }),
    [setError]
  )

  const handleSaveClick = useCallback(
    () => {

    clearErrors('first_name')
    clearErrors('email')
    const values = getValues();
     const {first_name, email } = getValues()
     if(!first_name){
       setError('first_name',{ message:'First Name is required'})
     }
    
     if (!email) {
       setError('email', { message: 'Email is required' });
     }
     //Check if form is invalid and return if have errors
     if(Object.keys(errors).length > 0 ){
       return
     }
     
      const data = new FormData();
      Object.keys(values).forEach(key => {
        if (['cohort', 'roadmaps_info'].includes(key)) {
          if (values[key].length > 0) {
            values[key].map(i => data.append(key, i.id))
          }
        } else {
          data.append(key, values[key])
        }
      })
      //Default values
      data.append('groups', ['User']);//Role
      data.append('username', values['email'])
      data.append('coach_write_only', user.id)
      let newUserId;
      addUser(data)
        .then(res => {
          newUserId = res.id;
          if (values.roadmaps_info) {
            return bulkAssignUserRoadmaps({
              userId: res.id,
              ids: values.roadmaps_info.map(i => i.id)
            })
          } else {
            return true
          }
        })
        .then(() => {
          history.push(`/manage/user/${newUserId}`);
        })
        .catch(e => handleError(e.response.data))
  }, [
    bulkAssignUserRoadmaps,
    addUser,
    handleError,
    history,
    user,
    getValues,
    setError,
    errors,
    clearErrors],
  );




  useEffect(() =>{
      setSaveButtonProps({
        disabled: addUserPending,
        onClick: handleSaveClick,
      })
    },
    [setSaveButtonProps, addUserPending, getValues, handleSaveClick],
  );


  const renderSaveButton = useCallback(
    () => (
      <Button className="btn-save" variant="link" {...saveButtonProps}>
        Add
      </Button>
    ),
    [saveButtonProps],
  )


  // useEffect(() => {
  //   fetchRoadmaps()
  // }, [fetchRoadmaps])

  // useEffect(() => {
  //   fetchCohorts()
  // }, [fetchCohorts])



  return (
    <div className="dashboard-coach-add-calls-page">
      <Header
        icon="back"
        title="Add Account"
        renderThirdColumn={renderSaveButton}
        thirdColumnClass="text-right"
        colSizes={['auto', undefined, 'auto']}
        border
        renderBackLink={renderBackLink}
        defaultBackLink={defaultBackLink}
      >
      </Header>

      {addUserPending && <Loader />}
      <Form className='mrm-p-1 mt-n5'
      onSubmit={handleSubmit(handleSaveClick)} 
      >
        <Form.Group controlId='first_name'>
          <Form.Label>
            First Name
          </Form.Label>
          <Form.Control
            name='first_name'
            type="text"
            isInvalid={errors.first_name}
            ref={register}
          />
          <p className="text-danger">{errors.first_name?.message}</p>
        </Form.Group>

        <Form.Group controlId='last_name'>
          <Form.Label>
            Last Name
          </Form.Label>
          <Form.Control
            name='last_name'
            type="text"
            isInvalid={errors.last_name}
            ref={register}
          />
        </Form.Group>

        <Form.Group controlId='email'>
          <Form.Label>
            Email
          </Form.Label>
          <Form.Control
            name='email'
            type="text"
            isInvalid={errors.email}
            ref={register}
          />
          <p className="text-danger">{errors.email?.message}</p>
        </Form.Group>

        <Form.Group controlId='phone_number'>
          <Form.Label>
            Phone Number
          </Form.Label>
          <Form.Control
            name='phone_number'
            type="number"
            isInvalid={errors.phone_number}
            ref={register}
          />
        </Form.Group>

        {/* <Form.Group controlId='cohort'>
          <Form.Label>
            Groups
          </Form.Label>
          <Controller 
          name="cohort"
          control={control}
          // isInvalid={errors.}
          defaultValue={customComponentDefaultValues['cohort']}
          render={({ onChange, value }) => 
            <Select
              isMulti
              defaultValue={[]}
              value={value}
              options={cohorts ? cohorts.results : null}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              onChange={onChange}
              styles={multiSelectStyles}
            />
          }
          />
          </Form.Group> */}

        {/* <Form.Group controlId='roadmaps_info'>
          <Form.Label>
            Roadmaps
          </Form.Label>
          <Controller 
          name="roadmaps_info"
          control={control}
          // isInvalid={errors.}
          defaultValue={customComponentDefaultValues['roadmaps_info']}
          render={({ onChange, value }) => 
            <Select
              isMulti
              defaultValue={[]}
              value={value}
              options={roadmaps ? roadmaps.results : null}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.title}
              onChange={onChange}
              styles={multiSelectStyles}
            />
          }
          />
        </Form.Group> */}

        <Form.Group controlId="is_approved" className="mb-0 d-flex flex-column mb-4">
          <Form.Label>
            Is Approved
          </Form.Label>
          <Controller 
          name="is_approved"
          control={control}
          isInvalid={errors.is_approved}
          defaultValue={customComponentDefaultValues['is_approved']}
          render={({ onChange, value }) => 
            <Switch
              onChange={onChange}
              checked={!!value}
              onColor='#2f80ed'
            />
          }
          />
        </Form.Group>

        </Form>

    </div>
  );
}

export function AddUserModal({
  show,
  onHide,
  handleUserSuccess
}) {

const history = useHistory()

const { user } = useFetchUser();

const { register, handleSubmit, control, errors, setError } = useForm({
  resolver: yupResolver(schema)
})

const { bulkAssignUserRoadmaps } = useBulkAssignUserRoadmaps()

const [saveButtonProps, setSaveButtonProps] = useState({})

const onSuccessfulSave = useCallback(() =>{
    onHide(true);
    handleUserSuccess();
  }, [ 
    onHide,
    handleUserSuccess
  ]);

const { addUser, addUserPending } = useAddUser()  

// const { roadmaps, fetchRoadmaps } = useFetchRoadmaps()

// const { fetchCohorts, cohorts } = useFetchCohorts()


const handleError = useCallback(
  err => Object.keys(err).forEach(key => {
    const errors = err[key]
    if (errors.length) {
      setError(key, { message: errors[0], type: 'remote' })
    }
  }),
  [setError]
)

// const desktopMultiSelectStyles = {
//   control: (styles, state) => {
//     const custom = {
//       ...styles,
//       borderRadius: '10px',
//       cursor: 'text',
//       borderColor: '#cccccc',
//       minHeight: '40px',
//       '&:hover': {
//         borderColor: '#cccccc',
//       },
//     }
//     return custom
//   }
// }

const handleSaveClick = useCallback(values => {
    const data = new FormData()
    Object.keys(values).forEach(key => {
      if ( ['cohort', 'roadmaps_info'].includes(key) ) {
        if (values[key].length > 0) {
          values[key].map(i => data.append(key, i.id))
        }
      } 
      else {
          data.append(key, values[key])
      }
    })
    //Default values
    data.append('groups', ['User']);//Role
    data.append('username', values['email'])
    data.append('coach_write_only', user.id)

    let newUserId;
    addUser(data)
      .then(res => {
        newUserId = res.id;
        if (values.roadmaps_info) {
          return bulkAssignUserRoadmaps({
            userId: res.id,
            ids: values.roadmaps_info.map(i => i.id)
          })
        } else {
          return true
        }
      })
      .then(() => {
        if (typeof onSuccessfulSave === "function") {
          onSuccessfulSave();
        } else {
          history.push(`/manage/user/${newUserId}`);
        }
      })
      .catch(e => handleError(e.response.data))
}, [
  bulkAssignUserRoadmaps,
  addUser,
  onSuccessfulSave,
  handleError,
  history,
  user
])

useEffect(() => setSaveButtonProps({
  disabled: addUserPending,
  onClick: handleSubmit(handleSaveClick),
}), [
  setSaveButtonProps,
  addUserPending,
  handleSubmit,
  handleSaveClick,
])

// useEffect(() => {
//   fetchRoadmaps()
// }, [fetchRoadmaps])

// useEffect(() => {
//   fetchCohorts()
// }, [fetchCohorts])



return (
  <Modal show={show} onHide={onHide} centered>
  <Modal.Header>
    <>
      <Button variant="secondary" onClick={() => onHide()} className="font-weight-bold">
        Cancel
      </Button>
      <h2 className="mrm-mt-1">Add Account</h2>
      <Button 
      {...saveButtonProps}
      >
        Add
      </Button>
    </>
  </Modal.Header>
  <Modal.Body>
    {addUserPending && <Loader />}
    <Form className='mrm-p-1'>

      <Form.Group controlId='first_name'>
      <Form.Label>
        First Name
      </Form.Label>
      <Form.Control
        name='first_name'
        type="text"
        isInvalid={errors.first_name}
        ref={register}
      />
      </Form.Group>

      <Form.Group controlId='last_name'>
      <Form.Label>
        Last Name
      </Form.Label>
      <Form.Control
        name='last_name'
        type="text"
        isInvalid={errors.last_name}
        ref={register}
      />
      </Form.Group>

      <Form.Group controlId='email'>
      <Form.Label>
        Email
      </Form.Label>
      <Form.Control
        name='email'
        type="text"
        isInvalid={errors.email}
        ref={register}
      />
      </Form.Group>

      <Form.Group controlId='phone_number'>
      <Form.Label>
        Phone Number
      </Form.Label>
      <Form.Control
        name='phone_number'
        type="number"
        isInvalid={errors.phone_number}
        ref={register}
      />
      </Form.Group>

      {/* <Form.Group controlId='cohort'>
      <Form.Label>
        Groups
      </Form.Label>
      <Controller 
      name="cohort"
      control={control}
      // isInvalid={errors.}
      defaultValue={customComponentDefaultValues['cohort']}
      render={({ onChange, value }) => 
        <Select
          isMulti
          defaultValue={[]}
          value={value}
          options={cohorts ? cohorts.results : null}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          onChange={onChange}
          styles={desktopMultiSelectStyles}
        />
      }
      />
      </Form.Group> */}
{/* 
      <Form.Group controlId='roadmaps_info'>
      <Form.Label>
        Roadmaps
      </Form.Label>
      <Controller 
      name="roadmaps_info"
      control={control}
      // isInvalid={errors.}
      defaultValue={customComponentDefaultValues['roadmaps_info']}
      render={({ onChange, value }) => 
        <Select
          isMulti
          defaultValue={[]}
          value={value}
          options={roadmaps ? roadmaps.results : null}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.title}
          onChange={onChange}
          styles={desktopMultiSelectStyles}
        />
      }
      />
      </Form.Group> */}

      <Form.Group controlId="is_approved" className="mb-0 d-flex flex-column mb-4">
      <Form.Label>
        Is Approved
      </Form.Label>
      <Controller 
      name="is_approved"
      control={control}
      isInvalid={errors.is_approved}
      defaultValue={customComponentDefaultValues['is_approved']}
      render={({ onChange, value }) => 
        <Switch
          onChange={onChange}
          checked={value}
          onColor='#2f80ed'
        />
      }
      />
      </Form.Group>

    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Container fluid>
      <Row className="d-flex justify-content-between">
        <Button variant="secondary" onClick={() => onHide()} className="font-weight-bold">Cancel</Button>
        <Button {...saveButtonProps}>Add</Button>  
      </Row>
    </Container>
  </Modal.Footer>
</Modal>
);
}

AddUserModal.propTypes = {};
AddUserModal.defaultProps = {};

CoachAddUserPage.propTypes = {};
CoachAddUserPage.defaultProps = {};