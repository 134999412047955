import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DASHBOARD_UPDATE_COACH_CALL_BEGIN,
  DASHBOARD_UPDATE_COACH_CALL_SUCCESS,
  DASHBOARD_UPDATE_COACH_CALL_FAILURE,
  DASHBOARD_UPDATE_COACH_CALL_DISMISS_ERROR,
} from './constants';

import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function updateCoachCall(args = {}) {
  const data = {
    call_name: args.call_name,
    timestamp: args.timestamp,
    meeting_link: args.meeting_link,
    participants: args.participants,
    recours_weekly: args.recours_weekly
  }
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_UPDATE_COACH_CALL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      const doRequest = axios.put(
        `${config.apiRootUrl}/upcoming-calls/${args.call_id}/`,
        data,
        createAxiosConfigWithAuth(getState())
      )
      doRequest.then(
        (res) => {
          dispatch({
            type: DASHBOARD_UPDATE_COACH_CALL_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_UPDATE_COACH_CALL_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );

    });

    return promise;
  };
}

export function dismissUpdateCoachCallError() {
  return {
    type: DASHBOARD_UPDATE_COACH_CALL_DISMISS_ERROR,
  };
}

export function useUpdateCoachCall() {
  const dispatch = useDispatch();

  const { updateCoachCallPending, updateCoachCallError } = useSelector(
    state => ({
      updateCoachCallPending: state.dashboard.updateCoachCallPending,
      updateCoachCallError: state.dashboard.updateCoachCallError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateCoachCall(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateCoachCallError());
  }, [dispatch]);

  return {
    updateCoachCall: boundAction,
    updateCoachCallPending,
    updateCoachCallError,
    dismissUpdateCoachCallError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_UPDATE_COACH_CALL_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateCoachCallPending: true,
        updateCoachCallError: null,
      };

    case DASHBOARD_UPDATE_COACH_CALL_SUCCESS:
      // The request is success
      return {
        ...state,
        updateCoachCallPending: false,
        updateCoachCallError: null,
      };

    case DASHBOARD_UPDATE_COACH_CALL_FAILURE:
      // The request is failed
      return {
        ...state,
        updateCoachCallPending: false,
        updateCoachCallError: action.data.error,
      };

    case DASHBOARD_UPDATE_COACH_CALL_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateCoachCallError: null,
      };

    default:
      return state;
  }
}
