import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    EXPORT_ROADMAP_ASSESSMENT_DATA_BEGIN,
    EXPORT_ROADMAP_ASSESSMENT_DATA_SUCCESS,
    EXPORT_ROADMAP_ASSESSMENT_DATA_FAILURE,
    EXPORT_ROADMAP_ASSESSMENT_DATA_DISMISS_ERROR,
} from './constants';

import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function exportRoadmapAssessmentData (args = {}) {
    return (dispatch, getState) => { // optionally you can have getState as the second argument
        dispatch({
            type: EXPORT_ROADMAP_ASSESSMENT_DATA_BEGIN,
        });

        const promise = new Promise((resolve, reject) => {
            const { roadmapId } = args;
            const doRequest = axios.post(
                `${config.apiRootUrl}/roadmaps/${roadmapId}/stats/`,
                args,
                createAxiosConfigWithAuth(getState())
            )

            doRequest.then(
                (res) => {
                    dispatch({
                        type: EXPORT_ROADMAP_ASSESSMENT_DATA_SUCCESS,
                        data: res.data,
                    });
                    resolve(res.data);
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                (err) => {
                    dispatch({
                        type: EXPORT_ROADMAP_ASSESSMENT_DATA_FAILURE,
                        data: { error: err },
                    });
                    reject(err);
                },
            );
        });

        return promise;
    };
}

export function dismissExportRoadmapAssessmentDataError () {
    return {
        type: EXPORT_ROADMAP_ASSESSMENT_DATA_DISMISS_ERROR,
    };
}

export function useExportRoadmapAssessmentData () {
    const dispatch = useDispatch();

    const { exportRoadmapAssessmentDataPending, exportRoadmapAssessmentDataError } = useSelector(
        state => ({
            exportRoadmapAssessmentDataPending: state.manage.exportRoadmapAssessmentDataPending,
            exportRoadmapAssessmentDataError: state.manage.exportRoadmapAssessmentDataError,
        }),
        shallowEqual,
    );

    const boundAction = useCallback((...args) => {
        return dispatch(exportRoadmapAssessmentData(...args));
    }, [dispatch]);

    const boundDismissError = useCallback(() => {
        return dispatch(dismissExportRoadmapAssessmentDataError());
    }, [dispatch]);

    return {
        exportRoadmapAssessmentData: boundAction,
        exportRoadmapAssessmentDataPending,
        exportRoadmapAssessmentDataError,
        dismissExportRoadmapAssessmentDataError: boundDismissError,
    };
}

export function reducer (state, action) {
    switch (action.type) {
        case EXPORT_ROADMAP_ASSESSMENT_DATA_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                exportRoadmapAssessmentDataPending: true,
                exportRoadmapAssessmentDataError: null,
            };

        case EXPORT_ROADMAP_ASSESSMENT_DATA_SUCCESS:
            // The request is success
            return {
                ...state,
                exportRoadmapAssessmentDataPending: false,
                exportRoadmapAssessmentDataError: null,
            };

        case EXPORT_ROADMAP_ASSESSMENT_DATA_FAILURE:
            // The request is failed
            return {
                ...state,
                exportRoadmapAssessmentDataPending: false,
                exportRoadmapAssessmentDataError: action.data.error,
            };

        case EXPORT_ROADMAP_ASSESSMENT_DATA_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                exportRoadmapAssessmentDataError: null,
            };

        default:
            return state;
    }
}
