// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { DashboardPage, AdminPage, RoadmapsPage, RoadmapLibraryPage, CoachAddUserPage, CoachAddCallsPage, CoachEditCallsPage, UpcomingCallsPage } from './';

export default {
  path: 'dashboard',
  component: DashboardPage,
  childRoutes: [
    { path: 'admin', component: AdminPage },
    { path: 'coach', component: AdminPage },
    { path: 'roadmaps', component: RoadmapsPage },
    { path: 'coach-add-user', component: CoachAddUserPage},
    { path: 'roadmap-library', component: RoadmapLibraryPage },
    { path: 'upcoming-calls', component: UpcomingCallsPage },
    { path: 'coach-add-calls', component: CoachAddCallsPage},
    { path: 'coach-edit-calls/:callId', component: CoachEditCallsPage},
  ],
};
